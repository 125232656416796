import {Component, Input, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoomaColor } from '@looma/shared/colors';
import gql from 'graphql-tag'
import { ApiDataService } from '../../../services/api-data.service';
import { catchError, combineLatest, debounceTime, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Utils } from '@looma/shared/utils';
import { LifecycleHooks } from '@looma/shared/lifecycle_utils';
type MetricName = 'HeartbeatStatus' | 'PlaybackStatus'
@LifecycleHooks()
@Component({
    selector: 'app-device-history-uptime-chart',
    templateUrl: './device-history-uptime-chart.component.html',
    styleUrls: ['./device-history-uptime-chart.component.scss']
})
export class DeviceHistoryUptimeChart implements OnInit {
    private _interval: number = 900

    @Input() metrics: MetricName[] = []
    @Input() deviceId: number

    @Input()
    set interval(value: number) {
        this._interval = Math.max(300, value)
    }

    get interval() {
        return this._interval
    }

    selectedMetric = new FormControl<MetricName | null>(null);
    startDate = new FormControl<Date | null>(null)
    today = new Date()
    intervalsPerDay = Math.ceil(86400 / this._interval)

    filteredData: number[] = []
    isOperational = true

    constructor(private svcApi: ApiDataService) {}

    ngOnInit(): void {
        combineLatest([
            this.selectedMetric.valueChanges,
            this.startDate.valueChanges
        ]).pipe(
            debounceTime(300),
            takeUntil(Utils.onDestroy(this)),
            map(([metric, startDate]) => ({ metric, startDate }))
        ).subscribe(() => this.updateChart())

        if (this.metrics.length > 0) {
            this.selectedMetric.setValue(this.metrics[0])
        }

        const defaultStartDate = new Date()
        defaultStartDate.setHours(0, 0, 0, 0)
        this.startDate.setValue(defaultStartDate)
    }

    get selectedMetricName(): MetricName {
        return this.selectedMetric.value
    }

    get getStartDate(): string {
        return this.startDate.value.toLocaleString()
    }

    get getEndDate(): string {
        const endDate = new Date(this.startDate.value)
        endDate.setUTCDate(this.startDate.value.getUTCDate() + 1)
        return endDate.toLocaleString()
    }

    get getOperationColor(): string {
        return this.getColor(Math.max(...this.getOperationalFilter()))

    }

    get getUptimePercentage() {
        const uptimePercent = this.filteredData.filter(x => x == 1).length / this.filteredData.filter(x => x != -1).length * 100
        return Number.isNaN(uptimePercent) ? 0 : uptimePercent.toFixed(2)
    }

    get hasData() {
        return !(this.filteredData.length == 0 || this.filteredData.every(x => x == -1))
    }

    getOperationalFilter() {
        const currentIndex = Math.min((this.today.getTime() - this.startDate.value.getTime()) / 1000 / this._interval, this.intervalsPerDay)
        return this.filteredData.slice(currentIndex - 5, currentIndex)
    }

    updateChart() {
        if (!(this.selectedMetric.value && this.startDate.value)) return
        this.getDeviceHistory()
    }

    getDeviceHistory() {
        this.svcApi.rawQuery({
            query: QUERY_FETCH_DEVICE_HISTORY,
            variables: {
                deviceId: this.deviceId,
                startDate: this.startDate.value,
                metric: this.selectedMetric.value,
                interval: this._interval
            }
        }).pipe(
            switchMap((value: any) => {
                const startDate = new Date(value.data.device_history.startDate)
                const values = value.data.device_history.values

                return of({ startDate, values })
            }),
            catchError((error: any, caught: any) => {
                return of({ startDate: this.startDate.value, values: []})
            })
        )
        .subscribe(value => {
            this.filteredData = value.values
            this.isOperational = this.getOperationalFilter().every(x => x == 1)
        })
    }

    getFilteredData() {
        return Array(this.intervalsPerDay).fill(true).map((_, i) => new Date(this.startDate.value.getTime() + this._interval * 1000 * i) > this.today ? -1 : Math.floor(Math.random() * 5))
    }

    getTickColor(value: number) {
        return this.getColor(value)
    }

    getTooltipText(value: number, index: number) {
        const tooltipDate = new Date(this.startDate.value.getTime() + this._interval * 1000 * index)
        let tickStatus
        switch(value) {
            case 0:
                tickStatus = 'Offline'
                break
            case 1:
                tickStatus = 'Active'
                break
            case 2:
            case 3:
            case 4:
                tickStatus = 'Inactive'
                break
            case -1:
                tickStatus = 'No Data'
                break
            default:
                tickStatus = 'Unknown'
        }
        return `${tooltipDate.toLocaleString()}: ${tickStatus}`
    }


    private getColor(value: number) {
        switch (value) {
            case 1:
                return LoomaColor.Green
            case 2:
                return LoomaColor.Yellow
            case 3:
                return LoomaColor.Orange
            case 4:
                return LoomaColor.Red
            case 0:
                return LoomaColor.Black
            default:
                return 'lightgray'
        }
    }

}

const QUERY_FETCH_DEVICE_HISTORY = gql `
    query getDeviceHistory(
        $deviceId:Int!,
        $startDate:Date!,
        $interval:Int!
        $metric:DeviceHistoricalMetric!
    ) {
        device_history(criteria: {
            deviceId:$deviceId,
            startDate:$startDate,
            metricType:$metric,
            timeInterval:$interval
        }) {
            startDate
            timeInterval
            values
        }
    }
`