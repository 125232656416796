<div app-looma-page [pageTitle]="title" pageIcon="playlist_play" style="height: 100%" [headerSideTpl]="headerSideTpl">
    <div style="display: flex; flex-direction: column; width: 100%; overflow: scroll">
        <div *ngFor="let segment of currentProgram?.deviceSlotSegments">
            <div class="section_header mat-elevation-z3">
                <div style="display: flex; flex-direction: column; width: 100%">

                    <div
                        style="display: flex; align-items: center; padding-left: 10px; width: 100%; justify-content: space-between;">
                        <div style="display: flex; align-items: center;">
                            <h3>{{ segment.name }}</h3>
                            <mat-chip-list style="padding-left: 10px">
                                <mat-chip style="color: red" *ngIf="getSurveyForSegment(segment?.id)?.isDirty">Content
                                    changed
                                </mat-chip>

                                <mat-chip style="color: green" *ngIf="getSurveyForSegment(segment?.id)?.isActive()">
                                    Active
                                </mat-chip>

                                <mat-chip style="color: blue" *ngIf="getSurveyForSegment(segment?.id)?.isPublished()">
                                    Published
                                    at {{ getSurveyForSegment(segment?.id)?.publishedAt }}
                                </mat-chip>
                            </mat-chip-list>

                            <button mat-button style="color:green" (click)="activateSurvey(segment)"
                                    *ngIf="canActivate(segment)">
                                Activate
                            </button>

                            <button mat-button (click)="publishSurvey(segment)" *ngIf="canPublish(segment)">Publish
                            </button>
                        </div>

                        <div>
                            <button mat-button (click)="addHtmlSurvey(segment)">
                                <mat-icon>add</mat-icon>
                                Upload html survey
                            </button>
                            <button mat-button (click)="addNewProduct(segment)">
                                <mat-icon>add</mat-icon>
                                Add new product
                            </button>

                            <button mat-button (click)="uploadHomePage(segment)">
                                <mat-icon>upload</mat-icon>
                                Home screen
                            </button>
                        </div>
                    </div>

                    <div *ngIf="getSurveyForSegment(segment?.id)?.publishedAt">
                        <mat-chip-list>
                            <mat-chip *ngIf="getSurveyForSegment(segment?.id)?.firebasePath"
                                      (click)="copyToClipboard(getSurveyForSegment(segment?.id)?.firebasePath)">
                                Firebase ref: {{ getSurveyForSegment(segment?.id)?.firebasePath }}
                            </mat-chip>

                            <mat-chip *ngIf="getSurveyForSegment(segment?.id)?.homeScreenUrl">
                                Home screen: <a [href]="getSurveyForSegment(segment?.id)?.homeScreenUrl"
                                                style="padding-left: 10px" target="_blank">
                                {{ getSurveyForSegment(segment?.id)?.homeScreenName }}
                            </a>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>

            <div class="section_content">
                <div class="survey_body">
                    <table mat-table [dataSource]="getSurveyProducts(segment.id)" #tbl
                           style="width: 100%">

                        <ng-container matColumnDef="brand_partner">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Brands</th>
                            <td mat-cell *matCellDef="let element">{{ element.brandProduct.brand_partner.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="brand_product">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Product</th>
                            <td mat-cell *matCellDef="let element" [matTooltip]="element.description">
                                <div>{{ element.brandProduct.name }}</div>
                                <div style="color: grey; font-size: 12px">UPC: {{ element.brandProduct.upc_code }}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="product_image">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Product image</th>
                            <td mat-cell *matCellDef="let element">
                                <a [href]="element.brandProduct.image_url" target="_blank"
                                   *ngIf="element.brandProduct.image_url">
                                    <img [src]="element.brandProduct.image_url"
                                         style="height: 50px; object-fit: contain"/>
                                </a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="product_placement_image">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Product placement image</th>
                            <td mat-cell *matCellDef="let element">
                                <a [href]="element.placementImageUrl" target="_blank" *ngIf="element.placementImageUrl">
                                    <img [src]="element.placementImageUrl" style="height: 50px; object-fit: contain"/>
                                </a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="wine_type">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Wine type</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.wineType?.displayName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="wine_pricing">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Wine pricing</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.winePricing.displayName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="tasting_note">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Tasting note</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.tastingNoteName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="tasting_note_image">
                            <th mat-header-cell *matHeaderCellDef style="width: 200px">Tasting note image</th>
                            <td mat-cell *matCellDef="let element">
                                <a [href]="element.tastingNoteImageUrl" target="_blank"
                                   *ngIf="element.tastingNoteImageUrl">
                                    <img [src]="element.tastingNoteImageUrl" style="height: 50px; object-fit: contain"/>
                                </a>
                            </td>
                        </ng-container>

                        <ng-container cdkColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef style="text-align: right">Action</th>
                            <td mat-cell *matCellDef="let element" style="text-align: right">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Product actions">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="onEdit(segment, element)">Edit</button>
                                    <button mat-menu-item (click)="onDelete(segment, element)">Delete</button>
                                </mat-menu>
                            </td>
                        </ng-container>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                                No product data found.
                            </td>
                        </tr>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #headerSideTpl>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button style="width: 100%" (click)="manageStaticContent()" color="warn">
            <mat-icon>toc</mat-icon>
            Question data
        </button>
    </div>
</ng-template>