import {Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {AngularFireModule} from '@angular/fire/compat';
import {LoginComponent} from './main/login/login.component';
import {AngularFireAuth, AngularFireAuthModule} from '@angular/fire/compat/auth';
import {DevicesListComponent} from './main/devices/components/devices-list/devices-list.component';
import {DashboardComponent} from './main/dashboard/components/dashboard/dashboard.component';
import {GraphQLModule} from '@looma/shared/graphql.module';
import {AppCommonModule} from './shared/common.module';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {DeviceDetailsComponent} from './main/devices/components/device-details/device-details.component';
import {ApiDataService} from './services/api-data.service';
import {FormsModule} from '@angular/forms';
import {WebNotificationsService} from './services/web-notifications.service';
import {MediaMatchService} from './services/media-match.service';
import {AngularFireMessaging, AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {DeviceEditComponent} from './main/devices/components/device-edit/device-edit.component';
import {AppReleasesComponent} from './main/devices/components/app-releases/app-releases.component';
import {BulkImportComponent} from './main/generic/bulk-import/bulk-import.component';
import {GenericModule} from './main/generic/generic.module';
import {RemotecontrolComponent} from './main/devices/components/remotecontrol/remotecontrol.component';
import {
    DeviceSlotImagesListComponent
} from './main/device-slot-images/components/device-slot-images-list/device-slot-images-list.component';
import {DeviceSlotImagesModule} from './main/device-slot-images/device-slot-images.module';
import {UsersListComponent} from './main/users/components/users-list/users-list.component';
import {UsersModule} from './main/users/users.module';
import {MediaFilesModule} from './main/media-files/media-files.module';
import {BrandsListComponent} from './main/brands/components/brands-list/brands-list.component';
import {BrandsModule} from './main/brands/brands.module';
import {ProgramsListComponent} from './main/retailer-promo-programs/components/programs-list/programs-list.component';
import {RetailerPromoProgramsModule} from './main/retailer-promo-programs/retailer-promo-programs.module';
import {ProductListComponent} from './main/products/product-list/product-list.component';
import {ProductsModule} from './main/products/products.module';
import {RetailerPromoCampaignsModule} from './main/retailer-promo-campaigns/retailer-promo-campaigns.module';
import {
    PromoScheduleProgramOverviewComponent
} from './main/retailer-promo-campaigns/components/promo-schedule-program-overview/promo-schedule-program-overview.component';
import {RetailersListComponent} from './main/retailers/components/retailers-list/retailers-list.component';
import {
    PromoPeriodPlaylistsOverviewComponent
} from './main/retailer-promo-campaigns/components/promo-period-playlists-overview/promo-period-playlists-overview.component';
import {RetailersModule} from './main/retailers/retailers.module';
import {StoresModule} from './main/stores/stores.module';
import {StoresListComponent} from './main/stores/components/stores-list/stores-list.component';
import {DeviceSlotsListComponent} from './main/device-slots/components/device-slots-list/device-slots-list.component';
import {DeviceSlotsModule} from './main/device-slots/device-slots.module';
import {
    MediaFileFilmVariablesComponent
} from './main/media-files/components/media-file-film-variables/media-file-film-variables.component';
import {AppComponent} from './app.component';
import {LayoutModule} from './layout/layout.module';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {DevicesModule} from './main/devices/devices.module';
import {
    CommandResultsListComponent
} from './main/devices/components/command-results-list/command-results-list.component';
import {secrets} from '@looma/config/secrets';
import {LoomaAuthModule} from '@looma/shared/auth/looma-auth.module';
import {AppName, AuthAcl} from '@looma/shared/auth/components/services/looma-auth.service';
import {LoomaSignedInGuard, UptimeSignedInGuard} from '@looma/shared/auth/auth.guards';
import {
    DeviceSlotImagePhotosListComponent
} from "./main/device-slot-images/components/device-slot-image-photos-list/device-slot-image-photos-list.component";
import {
    CampaignReportsListComponent
} from "./main/campaign-reports/campaign-reports-list/campaign-reports-list.component";
import {CampaignReportsModule} from "./main/campaign-reports/campaign-reports.module";
import {UserRoleListComponent} from "./main/users/components/user-role-list/user-role-list.component";
import {MediaPlaylistListComponent} from "./main/media-playlists/media-playlist-list/media-playlist-list.component";
import {
    MediaPlaylistAssignmentComponent
} from "./main/media-playlists/media-playlist-assignment/media-playlist-assignment.component";
import {
    PromoSubgroupPlaylistScheduleComponent
} from "./main/retailer-promo-campaigns/components/promo-subgroup-playlist-schedule/promo-subgroup-playlist-schedule.component";
import {
    DeviceSlotAssignmentListComponent
} from './layout/components/device-slot-assignment-list/device-slot-assignment-list.component';
import {MatBadgeModule} from "@angular/material/badge";
import {
    SegmentDeviceSlotAssignmentComponent
} from "./main/retailer-promo-campaigns/components/segment-device-slot-assignment/segment-device-slot-assignment.component";
import {
    ProgramDeviceSlotTypeAssignmentComponent
} from "./main/retailer-promo-programs/components/program-device-slot-type-assignment/program-device-slot-type-assignment.component";
import {
    PromoSchedulesListComponent
} from "./main/retailer-promo-campaigns/components/promo-schedules-list/promo-schedules-list.component";
import {MediaContentsListComponent} from "./main/media-content/media-contents-list/media-contents-list.component";
import {MediaContentModule} from "./main/media-content/media-content.module";
import {ReportsModule} from "./main/reports/reports.module";
import {GenericReportsComponent} from "./main/reports/generic-reports/generic-reports.component";
import {SurveyDataListComponent} from "./main/survey/survey-data-list/survey-data-list.component";
import {SurveyModule} from "./main/survey/survey.module";
import {
    PromoPeriodSurveyOverviewComponent
} from "./main/retailer-promo-campaigns/components/promo-period-survey-overview/promo-period-survey-overview.component";
import {AppNavigationConfig} from "./navigation/navigation";
import {EnvironmentProvider} from "@looma/shared/services/environment-provider.service";
import {UploadBottomSheetSelectorComponent} from "@looma/shared/services/upload.service";
import {
    ScheduleCalendarComponent
} from "./main/retailer-promo-campaigns/components/schedule-calendar/schedule-calendar.component";
import {RemoteDataService} from "@looma/shared/services/remote_data_service";
import {ApolloModule} from "apollo-angular";
import {StoreGroupsComponent} from "./main/store-groups/components/store-groups/store-groups.component";
import {StoreGroupsModule} from "./main/store-groups/store-groups.module";
import {
    RetailerStoreGroupsAssignmentComponent
} from "./main/store-groups/components/retailer-store-groups-assignment/retailer-store-groups-assignment.component";
import {
    ProgramSubmissionsOverviewComponent
} from "./main/retailer-promo-campaigns/components/program-submissions-overview/program-submissions-overview.component";
import {
    ProgramSubmissionProductsComponent
} from "./main/retailer-promo-campaigns/components/program-subission-products/program-submission-products.component";
import {
    BrandSubmissionStatusComponent
} from "./main/retailer-promo-campaigns/components/brand-submission-status/brand-submission-status.component";
import {CustomBrandsComponent} from "./main/brands/components/custom-brands/custom-brands.component";
import {CustomProductsComponent} from "./main/products/custom-products/custom-products.component";
import {UptimeEnvironmentProvider} from "./services/marketplace.envrionment.provider";
import {
    EnrolledProductsComponent
} from "./main/retailer-promo-campaigns/components/enrolled-products/enrolled-products.component";
import {
    WineRecsCampaignGenerationComponent
} from "./main/retailer-promo-campaigns/components/wine-recs-campaign-generation/wine-recs-campaign-generation.component";
import {
    HomescreenPlaylistComponentComponent
} from "./main/retailer-promo-campaigns/components/homescreen-playlist-component/homescreen-playlist-component.component";


const appRoutes: Routes = [{
    path: '',
    redirectTo: AppNavigationConfig.navigationEntryPoint,
    pathMatch: 'full'
}, {
    path: 'auth/login',
    component: LoginComponent
}, {
    path: 'devices',
    component: DevicesListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'devices/:id',
    component: DeviceDetailsComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'devices/:id/edit',
    component: DeviceEditComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'devices/:id/remote_control',
    component: RemotecontrolComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'command-results',
    component: CommandResultsListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'app-releases',
    component: AppReleasesComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'bulk-import',
    component: BulkImportComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'device-slot-images',
    component: DeviceSlotImagesListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'device-slot-images/:imageId',
    component: DeviceSlotImagePhotosListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'users',
    component: UsersListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'user-roles',
    component: UserRoleListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'media-content/film-variables/:id',
    component: MediaFileFilmVariablesComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'media-content',
    component: MediaContentsListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'brands',
    component: BrandsListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'custom-brands',
    component: CustomBrandsComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'brand-products',
    component: ProductListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'custom-products',
    component: CustomProductsComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'retailers',
    component: RetailersListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'stores',
    component: StoresListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'store-groups',
    component: StoreGroupsComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'store-groups/:retailer_id',
    component: RetailerStoreGroupsAssignmentComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'device-slots',
    component: DeviceSlotsListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-programs',
    component: ProgramsListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-periods/:id/type-assignment',
    component: SegmentDeviceSlotAssignmentComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-programs/:id/type-assignment',
    component: ProgramDeviceSlotTypeAssignmentComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-schedules',
    component: PromoSchedulesListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'schedule-calendar',
    component: ScheduleCalendarComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-schedules/:id/program-overview',
    component: PromoScheduleProgramOverviewComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-periods/:id/playlists',
    component: PromoPeriodPlaylistsOverviewComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-periods/:id/surveys',
    component: PromoPeriodSurveyOverviewComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-periods/:id/homescreen-playlist',
    component: HomescreenPlaylistComponentComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-periods/:id/surveys/static-data',
    component: SurveyDataListComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'promo-periods/:promoPeriodId/promo-subgroup-playlist-schedule/:segmentId',
    component: PromoSubgroupPlaylistScheduleComponent,
    canActivate: [LoomaSignedInGuard]
}, {
    path: 'brand-campaign-reports',
    component: CampaignReportsListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'media-playlists',
    component: MediaPlaylistListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'media-playlists/:id',
    component: MediaPlaylistAssignmentComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'reports',
    component: GenericReportsComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'survey-data/:id',
    component: SurveyDataListComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'promo-schedules/program-submissions/promo-period/:promoPeriodId/promo-program/:promoProgramId',
    component: ProgramSubmissionsOverviewComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'promo-schedules/program-submissions/promo-program/:promoProgramId',
    component: BrandSubmissionStatusComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'promo-schedules/program-submissions/:submissionId/products',
    component: ProgramSubmissionProductsComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'promo-schedules/program-submissions/:scheduleId/enrolled_products',
    component: EnrolledProductsComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: 'promo-schedules/campaign-generation/wine-recs/:retailerPromoPeriodId',
    component: WineRecsCampaignGenerationComponent,
    canActivate: [UptimeSignedInGuard]
}, {
    path: '**',
    redirectTo: AppNavigationConfig.navigationNotFoundRedirect,
}];

@Injectable()
export class GoogleMapsConfig {
    apiKey: string;
    libraries: string[];

    constructor() {
        this.apiKey = secrets.google.apiKey;
    }
}


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UploadBottomSheetSelectorComponent,
    ],
    imports: [
        ApolloModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),

        AngularFireModule.initializeApp(secrets.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,

        // Material
        MatButtonModule,
        MatIconModule,

        GenericModule,

        FormsModule,
        AppCommonModule,

        // App modules
        LayoutModule,

        GraphQLModule,
        AppCommonModule,

        DashboardModule,
        DevicesModule,
        DeviceSlotImagesModule,
        UsersModule,
        SurveyModule,
        MediaFilesModule,
        MediaContentModule,
        CampaignReportsModule,
        BrandsModule,
        ProductsModule,
        RetailersModule,
        StoresModule,
        StoreGroupsModule,
        DeviceSlotsModule,
        RetailerPromoProgramsModule,
        RetailerPromoCampaignsModule,
        LoomaAuthModule.forRoot({
            app: AppName.UptimeDashboard,
            acl: AuthAcl.UptimeAccess,
            loginUrl: '/auth/login',
            dashboardUrl: AppNavigationConfig.navigationEntryPoint,
        }),
        ReportsModule,
        MatBadgeModule
    ],
    providers: [
        AngularFirestore,
        AngularFireStorage,
        AngularFireAuth,
        AngularFireMessaging,

        ApiDataService,
        WebNotificationsService,
        MediaMatchService,
        {
            provide: EnvironmentProvider,
            useClass: UptimeEnvironmentProvider,
        },
        {provide: RemoteDataService, useExisting: ApiDataService, multi: false},

    ],
    exports: [
        DeviceSlotAssignmentListComponent
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule {
}


