import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiDataService} from '../../../../services/api-data.service';
import {LayoutService} from '../../../../services/layout.service';
import {UploadService} from '@looma/shared/services/upload.service';
import {ToastNotificationService} from '../../../../services/toast-notification.service';
import {RetailerPromoProgram} from '@looma/shared/models/retailer_promo_program';
import {RetailerPromoPeriod} from '@looma/shared/models/retailer_promo_periods';
import {BrandPromoCampaign} from '@looma/shared/models/brand_promo_campaign';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {switchMap, throwError} from 'rxjs';
import {Utils} from '@looma/shared/utils';
import {LifecycleHooks} from '@looma/shared/lifecycle_utils';
import {CdkDragDrop, copyArrayItem, moveItemInArray} from '@angular/cdk/drag-drop';
import {HomeScreenDialogComponent} from './home-screen-dialog.component';
import {BrandTakeoverDialogComponent} from './brand-takeover-dialog.component';
import gql from 'graphql-tag';
import {ProductSpotlightDialogComponent} from "./product-spotlight-dialog.component";
import {GenericVideoDialogComponent} from "./generic-video-dialog.component";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";

export interface ContentInput {
    productSpotlight?: {
        productId: string;
        productName: string;
        imageUrl: string;
        upcCode: string;
    };
    brandTakeover?: {
        videoUrl: string;
    };
    homeScreen?: {
        heroImageUrl: string;
        callToActionText: string;
    };
    genericVideo?: {
        videoUrl: string;
    };
}

@LifecycleHooks()
@Component({
    selector: 'app-homescreen-playlist-component',
    templateUrl: './homescreen-playlist-component.component.html',
    styleUrls: ['./homescreen-playlist-component.component.scss']
})
export class HomescreenPlaylistComponentComponent implements OnInit {
    title = 'Homescreen Playlist Management';
    promoPeriod: RetailerPromoPeriod;
    promoProgram: RetailerPromoProgram;
    deviceSlotSegments: DeviceSlotSegment[];
    campaigns: BrandPromoCampaign[] = [];
    selectedSegment: DeviceSlotSegment | null = null;
    selectedCampaign: BrandPromoCampaign | null = null;
    allPlaylists: any[] = [];
    playlist: PlaylistItem[] = [];
    libraryItems: PlaylistItem[] = [];
    filteredCampaigns: BrandPromoCampaign[] = [];
    existingPlaylistId: string;
    isSaving = false;
    lastRemovedItem: PlaylistItem | null = null;
    lastRemovedIndex: number | null = null;

    segmentCampaignsMap: Map<string, BrandPromoCampaign[]> = new Map();

    static open(router: Router, promoPeriod: RetailerPromoPeriod, prog: RetailerPromoProgram) {
        const extras = {
            queryParams: prog ? {prog: prog.id} : {}
        };
        router.navigate([`promo-periods/${promoPeriod.id}-${prog.id}/homescreen-playlist`], extras);
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private svcApi: ApiDataService,
        private router: Router,
        public svcLayout: LayoutService,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService
    ) {
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id') || '';
        const chunks = id.split('-');

        if (chunks.length !== 2) {
            return;
        }

        const promoPeriodId = parseInt(chunks[0]);
        const programId = parseInt(chunks[1]);

        this.loadData(promoPeriodId, programId).subscribe(() => {
            this.organizeCampaignsBySegment();
            if (this.deviceSlotSegments.length === 1) {
                this.selectSegment(this.deviceSlotSegments[0]);
            }
        });
    }

    private loadData(promoPeriodId: number, programId: number) {
        return this.svcApi.rawQuery({
            query: FETCH_RETAILER_PROMO_PROGRAM,
            variables: {filter: {id: programId}}
        }).pipe(
            catchError(error => throwError(() => error)),
            tap(value => {
                const raw = value.data["retailerPromoPrograms"].retailerPromoPrograms[0];
                if (!raw) {
                    throw new Error('Promo program not found');
                }
                this.promoProgram = new RetailerPromoProgram().assign(raw);
                this.title = this.title + " - " + this.promoProgram.name
            }),
            switchMap(() => this.svcApi.rawQuery({
                query: FETCH_RETAILER_PROMO_PERIOD,
                variables: {id: promoPeriodId, promoProgramId: programId}
            })),
            catchError(error => throwError(() => error)),
            tap(value => {
                this.promoPeriod = new RetailerPromoPeriod().assign(value.data["retailerPromoPeriod"]);
                this.deviceSlotSegments = this.promoPeriod.deviceSlotSegments
                this.campaigns = this.promoPeriod.brandCampaigns.filter(
                    s => s.promoProgram.id === String(programId)
                );
            }),
            switchMap(() => this.loadExistingPlaylist(promoPeriodId, programId)),
            takeUntil(Utils.onDestroy(this))
        ).pipe(
            catchError((error) => {
                console.error('Error loading data:', error);
                this.svcLayout.showSnackMessage('Error loading data');
                return throwError(() => error);
            })
        );
    }

    private loadExistingPlaylist(promoPeriodId: number, programId: number) {
        return this.svcApi.rawQuery({
            query: FETCH_HOMESCREEN_PLAYLIST,
            variables: {promoPeriodId: promoPeriodId, programId: programId},
            fetchPolicy: 'no-cache'
        }).pipe(
            tap(result => {
                const homeScreenPlaylists = result.data["homeScreenPlaylists"];
                this.allPlaylists = homeScreenPlaylists
            })
        );
    }

    selectCampaign(campaign: BrandPromoCampaign): void {
        this.selectedCampaign = campaign;
    }

    addHomeScreenContent(): void {
        if (!this.selectedCampaign) {
            return;
        }
        this.svcLayout.openDialogForResult(HomeScreenDialogComponent, {width: '600px'}).subscribe(result => {
            if (result) {
                this.libraryItems.push({...result, campaignId: this.selectedCampaign.id});
            }
        });
    }


    selectSegment(segment: DeviceSlotSegment): void {
        this.selectedSegment = segment;
        this.selectedCampaign = null;
        this.libraryItems = [];
        this.filteredCampaigns = this.segmentCampaignsMap.get(segment.id) || [];
        if (this.filteredCampaigns.length === 1) {
            this.selectCampaign(this.filteredCampaigns[0]);
        }

        const segmentId = this.selectedSegment != null ? this.selectedSegment.id : this.deviceSlotSegments[0].id
        const playlist = this.allPlaylists.find(p => p.deviceSlotSegment.id == segmentId)
        this.playlist = playlist ? playlist["items"] : []
        this.existingPlaylistId = playlist["id"];
    }

    addProductSpotlight(): void {
        if (!this.selectedCampaign) {
            return
        }
        this.svcLayout.openDialogForResult(ProductSpotlightDialogComponent, {
            width: '600px',
            data: {campaign: this.selectedCampaign}
        }).subscribe(result => {
            if (result) {
                this.libraryItems.push({...result, campaignId: this.selectedCampaign.id});
            }
        });
    }

    addBrandTakeover(): void {
        if (!this.selectedCampaign) {
            return
        }
        this.svcLayout.openDialogForResult(BrandTakeoverDialogComponent, {width: '600px'}).subscribe(result => {
            if (result) {
                this.libraryItems.push({...result, campaignId: this.selectedCampaign.id});
            }
        });
    }

    addToPlaylist(item: PlaylistItem): void {
        this.playlist.push({...item});
    }

    private organizeCampaignsBySegment(): void {
        this.segmentCampaignsMap.clear();
        for (const segment of this.deviceSlotSegments) {
            const segmentCampaigns = this.campaigns.filter(campaign => {
                return campaign.slotAssignments.some(x => {
                    return String(x.deviceSlotSegmentId) === String(segment.id);
                });
            });
            this.segmentCampaignsMap.set(segment.id, segmentCampaigns);
        }
    }

    removeItem(index: number): void {
        this.lastRemovedItem = this.playlist[index];
        this.lastRemovedIndex = index;
        this.playlist.splice(index, 1);
        this.svcToastNotif.showSuccess('Item removed', 5000)
    }

    onDrop(event: CdkDragDrop<PlaylistItem[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            const itemToCopy = {...event.previousContainer.data[event.previousIndex]};
            copyArrayItem([itemToCopy], event.container.data, 0, event.currentIndex);
        }
    }

    savePlaylist(): void {
        if (!this.playlist.length) {
            return
        }
        this.isSaving = true;

        const input = {
            id: this.existingPlaylistId,
            retailerPromoPeriodId: this.promoPeriod.id,
            retailerPromoProgramId: this.promoProgram.id,
            deviceSlotSegmentId: this.selectedSegment.id,
            items: this.playlist.map((item, index) => {
                const contentInput: ContentInput = {};
                switch (item.type) {
                    case 'PRODUCT_SPOTLIGHT':
                        contentInput.productSpotlight = {
                            productId: item.content.productId!,
                            productName: item.content.productName!,
                            upcCode: item.content.upcCode!,
                            imageUrl: item.content.imageUrl!
                        };
                        break;
                    case 'BRAND_TAKEOVER':
                        contentInput.brandTakeover = {videoUrl: item.content.videoUrl!};
                        break;
                    case 'HOME_SCREEN':
                        contentInput.homeScreen = {
                            heroImageUrl: item.content.heroImageUrl!,
                            callToActionText: item.content.callToActionText!
                        };
                        break;
                    case 'GENERIC_VIDEO':
                        contentInput.genericVideo = {
                            videoUrl: item.content.videoUrl!
                        };
                        break;
                }
                return {
                    type: item.type,
                    order: index,
                    content: contentInput,
                    campaignId: item.campaignId
                };
            })
        };

        this.svcApi.mutate(SAVE_PLAYLIST_MUTATION, {input: input}).pipe(
            catchError(error => {
                console.error('Error saving playlist:', error);
                this.isSaving = false;
                this.svcToastNotif.showError(`Failed to save playlist ${error.message}`);
                return throwError(() => error);
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(response => {
            this.isSaving = false;
            this.svcToastNotif.showSuccess('Playlist saved successfully', 5000);
        });
    }

    getItemIcon(item: PlaylistItem): string {
        switch (item.type) {
            case 'HOME_SCREEN':
                return 'home';
            case 'BRAND_TAKEOVER':
                return 'movie';
            case 'PRODUCT_SPOTLIGHT':
                return 'shopping_bag';
            case 'GENERIC_VIDEO':
                return 'movie';
            default:
                return 'error';
        }
    }

    getItemName(item: PlaylistItem): string {
        switch (item.type) {
            case 'HOME_SCREEN':
                return '';
            case 'BRAND_TAKEOVER':
                return '';
            case 'GENERIC_VIDEO':
                return '';
            case 'PRODUCT_SPOTLIGHT':
                return item.content.productName + " " + item.content.upcCode || 'Product Spotlight';
            default:
                return 'Unknown Item';
        }
    }

    getItemThumbnail(item: PlaylistItem): string {
        switch (item.type) {
            case 'HOME_SCREEN':
                return item.content.heroImageUrl || '';
            case 'PRODUCT_SPOTLIGHT':
                return item.content.imageUrl || '';
            default:
                return '';
        }
    }

    getItemType(type: "HOME_SCREEN" | "BRAND_TAKEOVER" | "PRODUCT_SPOTLIGHT" | "GENERIC_VIDEO") {
        switch (type) {
            case "BRAND_TAKEOVER":
                return "BRAND TAKEOVER";
            case "HOME_SCREEN":
                return "HOME SCREEN";
            case "PRODUCT_SPOTLIGHT":
                return "PRODUCT SPOTLIGHT";
            case "GENERIC_VIDEO":
                return "GENERIC VIDEO";
        }
    }

    toggleVideo(video: HTMLVideoElement): void {
        video.paused ? video.play() : video.pause();
    }

    getCampaign(campaignId: string): BrandPromoCampaign {
        return this.campaigns.find(campaign => campaign.id === campaignId);
    }

    addGenericVideo() {
        if (!this.selectedCampaign) {
            return;
        }
        this.svcLayout.openDialogForResult(GenericVideoDialogComponent, {width: '600px'}).subscribe(result => {
            if (result) {
                this.libraryItems.push({...result, campaignId: this.selectedCampaign.id});
            }
        });
    }

    openMediaInNewTab(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }
}

interface PlaylistItem {
    type: 'HOME_SCREEN' | 'BRAND_TAKEOVER' | 'PRODUCT_SPOTLIGHT' | 'GENERIC_VIDEO';
    campaignId: string;
    content: {
        callToActionText?: string;
        heroImageUrl?: string;
        videoUrl?: string;
        productId?: string;
        productName?: string;
        imageUrl?: string;
        upcCode?: string;
    };
}

const SAVE_PLAYLIST_MUTATION = gql`
    mutation upsertHomeScreenPlaylist($input: HomeScreenPlaylistInput!) {
        upsertHomeScreenPlaylist(input: $input) {
            success
            message
        }
    }
`;

export const FETCH_HOMESCREEN_PLAYLIST = gql`
    query homeScreenPlaylists($promoPeriodId: ID!, $programId: ID!) {
        homeScreenPlaylists(promoPeriodId: $promoPeriodId, programId: $programId) {
            id
            retailerPromoProgram { id name }
            retailerPromoPeriod { id name brandCampaigns { id name brandPartner { id name } promoProgram { id } } }
            deviceSlotSegment {
                id
            }
            items {
                campaignId
                type content { __typename ... on ProductSpotlight { productId productName imageUrl upcCode } ... on BrandTakeover { videoUrl } ... on HomeScreenHomeContent { heroImageUrl callToActionText } ... on GenericVideo { videoUrl }}
            }
        }
    }
`;

export const FETCH_RETAILER_PROMO_PERIOD = gql`
    query retailerPromoPeriod($id: ID!, $promoProgramId: ID!) {
        retailerPromoPeriod(id: $id) {
            id
            name
            deviceSlotSegments(filter: {promoProgramId: $promoProgramId}) {
                id
                name
            }
            brandCampaigns {
                id
                name
                slotAssignments {
                    deviceSlotSegmentId
                }
                brandPartner {
                    id
                    name
                    products {
                        id
                        name
                        upc_code
                    }
                }
                featuredBrands {
                    id
                    name
                }
                featuredProducts {
                    id
                    name
                    image_url
                    upc_code
                }
                promoProgram {
                    id
                }
            }
        }
    }
`;

export const FETCH_RETAILER_PROMO_PROGRAM = gql`
    query retailerPromoPrograms($filter: RetailerPromoProgramFeedFilter!) {
        retailerPromoPrograms(filter: $filter) { retailerPromoPrograms { id name } }
    }
`;
