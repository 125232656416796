import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {UploadService, UploadSession} from '@looma/shared/services/upload.service';
import {takeUntil} from 'rxjs/operators';
import {Utils} from '@looma/shared/utils';
import firebase from 'firebase/compat/app';
import {ToastNotificationService} from '../../../../services/toast-notification.service';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {LifecycleHooks} from '@looma/shared/lifecycle_utils';

@LifecycleHooks()
@Component({
    selector: 'app-home-screen-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule
    ],
    template: `
        <div class="dialog-wrapper">
            <h2 class="dialog-title" mat-dialog-title>
                Create Home Screen Content
            </h2>
            <mat-dialog-content style="padding-top: 10px">
                <form [formGroup]="form" class="content-form">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Call to Action Text</mat-label>
                        <input matInput formControlName="callToAction" required/>
                        <mat-error *ngIf="form.get('callToAction')?.invalid">
                            Call to action text is required
                        </mat-error>
                    </mat-form-field>

                    <div class="image-section">
                        <div *ngIf="heroImageUrl" class="image-preview-container">
                            <div class="image-preview">
                                <img [src]="heroImageUrl" alt="Hero preview"/>
                            </div>
                        </div>
                        <button mat-stroked-button
                                type="button"
                                class="upload-button"
                                (click)="uploadHeroImage()"
                                [disabled]="isUploading">
                            <mat-icon>cloud_upload</mat-icon>
                            <span>{{ isUploading ? 'Uploading...' : 'Upload Hero Image' }}</span>
                        </button>
                    </div>
                </form>
            </mat-dialog-content>

            <mat-dialog-actions align="end" class="dialog-actions">
                <button mat-button (click)="onCancel()">Cancel</button>
                <button mat-flat-button
                        color="primary"
                        [disabled]="!form.valid || !heroImageUrl || isUploading"
                        (click)="onSave()">
                    Create Content
                </button>
            </mat-dialog-actions>
        </div>
    `,
    styles: [`
        .dialog-wrapper {
            padding: 24px;
            min-width: 400px;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        }

        .dialog-title {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            margin-bottom: 16px;
        }

        mat-dialog-content {
            padding-bottom: 16px;
        }

        .content-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .full-width {
            width: 100%;
        }

        .image-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            margin-top: 16px;
        }

        .image-preview-container {
            width: 100%;
            max-width: 480px;
            margin: 0 auto;
            background: #f5f5f5;
            border-radius: 8px;
            padding: 16px;
        }

        .image-preview {
            width: 100%;
            position: relative;
            padding-top: 56.25%;
            background: #fff;
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }

        .image-preview img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: #fff;
        }

        .upload-button {
            display: flex;
            align-items: center;
            gap: 8px;
            min-width: 140px;
        }

        .dialog-actions {
            padding-top: 16px;
            border-top: 1px solid #eee;
        }
    `]
})
export class HomeScreenDialogComponent implements OnInit {
    form: FormGroup;
    heroImageUrl: string;
    isUploading = false;
    private uploadSession: UploadSession;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<HomeScreenDialogComponent>,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService
    ) {
        this.form = this.fb.group({
            callToAction: ['', Validators.required]
        });

        this.uploadSession = this.svcUpload.getUploadSession('homescreen_hero', {
            fileTypes: ['image'],
            multiSelection: false,
            baseUploadPath: "surveys/homescreen/homescreen_hero"
        });
    }

    ngOnInit() {
        this.uploadSession.onFileUploaded()
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe((file) => {
                const storage = firebase.storage();
                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.heroImageUrl = url;
                    this.isUploading = false;
                });
            });
    }

    uploadHeroImage() {
        this.isUploading = true;
        this.uploadSession.openPicker();
    }

    onSave() {
        if (this.form.valid && this.heroImageUrl) {
            this.dialogRef.close({
                type: 'HOME_SCREEN',
                content: {
                    callToActionText: this.form.get('callToAction')?.value,
                    heroImageUrl: this.heroImageUrl,
                }
            });
        }
    }

    onCancel() {
        this.dialogRef.close();
    }
}
