<div app-looma-page [pageTitle]="title" pageIcon="playlist_play" style="height: 100%">
    <div class="playlist-container">
        <!-- Device Slot Segments Panel -->
        <div class="segments-panel">
            <h2>
                <mat-icon>devices</mat-icon>
                Segments
            </h2>
            <div class="segment-list">
                <mat-card *ngFor="let segment of deviceSlotSegments"
                          tabindex="0"
                          [class.selected]="selectedSegment?.id === segment.id"
                          (click)="selectSegment(segment)"
                          (keydown.enter)="selectSegment(segment)">
                    <mat-card-content>
                        <div class="segment-name">{{ segment.name }}</div>
                    </mat-card-content>
                </mat-card>

                <div *ngIf="!deviceSlotSegments || deviceSlotSegments.length === 0" class="empty-state">
                    <mat-icon class="empty-icon">info</mat-icon>
                    <p>No device slot segments available</p>
                </div>
            </div>
        </div>

        <!-- Campaigns Panel -->
        <div class="campaigns-panel" [class.disabled]="!selectedSegment">
            <h2>
                <mat-icon>campaign</mat-icon>
                Campaigns
            </h2>
            <div *ngIf="selectedSegment" class="selected-segment-info">
                <mat-chip-set>
                    <mat-chip>{{ selectedSegment.name }}</mat-chip>
                </mat-chip-set>
            </div>
            <div class="campaign-list">
                <mat-card *ngFor="let campaign of filteredCampaigns"
                          tabindex="0"
                          [class.selected]="selectedCampaign?.id === campaign.id"
                          (click)="selectCampaign(campaign)"
                          (keydown.enter)="selectCampaign(campaign)">
                    <mat-card-content>
                        <div class="campaign-name">{{ campaign.name }}</div>
                        <div class="brand-name">{{ campaign.brandPartner?.name }}</div>
                    </mat-card-content>
                </mat-card>

                <div *ngIf="!selectedSegment" class="empty-state">
                    <mat-icon class="empty-icon">info</mat-icon>
                    <p>Select a device slot segment first</p>
                </div>

                <div *ngIf="selectedSegment && (!filteredCampaigns || filteredCampaigns.length === 0)" class="empty-state">
                    <mat-icon class="empty-icon">info</mat-icon>
                    <p>No campaigns available for this segment</p>
                </div>
            </div>
        </div>

        <!-- Content Creation Panel -->
        <div class="content-panel" [class.disabled]="!selectedCampaign">
            <h2 *ngIf="selectedCampaign">
                <mat-icon>create</mat-icon>
                Content Creation - {{ selectedCampaign.name }}
            </h2>
            <h2 *ngIf="!selectedCampaign">
                <mat-icon>info</mat-icon>
                Select a campaign to create content
            </h2>
            <div class="content-options" *ngIf="selectedCampaign; else noCampaign">
                <div class="option-cards">
                    <mat-card class="content-option" tabindex="0"
                              (click)="addHomeScreenContent()"
                              (keydown.enter)="addHomeScreenContent()">
                        <mat-card-content>
                            <mat-icon>home</mat-icon>
                            <span>Create Home Screen Content</span>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="content-option" tabindex="0"
                              (click)="addBrandTakeover()"
                              (keydown.enter)="addBrandTakeover()">
                        <mat-card-content>
                            <mat-icon>movie</mat-icon>
                            <span>Create Brand Takeover</span>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="content-option" tabindex="0"
                              (click)="addProductSpotlight()"
                              (keydown.enter)="addProductSpotlight()">
                        <mat-card-content>
                            <mat-icon>shopping_bag</mat-icon>
                            <span>Create Product Spotlight</span>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="content-option" tabindex="0"
                              (click)="addGenericVideo()"
                              (keydown.enter)="addGenericVideo()">
                        <mat-card-content>
                            <mat-icon>movie</mat-icon>
                            <span>Create Generic Video</span>
                        </mat-card-content>
                    </mat-card>
                </div>

                <!-- Library Section -->
                <div class="library-section">
                    <h3>Content Library</h3>
                    <div cdkDropList
                         id="libraryList"
                         #libraryList="cdkDropList"
                         [cdkDropListData]="libraryItems"
                         [cdkDropListConnectedTo]="['playlistList']"
                         class="library-items"
                         (cdkDropListDropped)="onDrop($event)">
                        <div *ngFor="let item of libraryItems"
                             cdkDrag
                             [cdkDragData]="item"
                             class="playlist-item"
                             [attr.data-type]="item.type"
                             [attr.aria-label]="getItemType(item.type) + ' - ' + getItemName(item)">
                            <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
                            <div class="thumbnail-container" matTooltip="{{ getItemName(item) }}"
                                 [matTooltipShowDelay]="500">
                                <ng-container
                                    *ngIf="item.type === 'BRAND_TAKEOVER' && item.content.videoUrl; else nonVideo">
                                    <video muted class="video-player" #videoPlayer
                                           (click)="openMediaInNewTab(item.content.videoUrl)">
                                        <source [src]="item.content.videoUrl" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </ng-container>
                                <ng-template #nonVideo>
                                    <ng-container *ngIf="getItemThumbnail(item); else defaultIcon">
                                        <img [src]="getItemThumbnail(item)" alt="Thumbnail" class="thumbnail"
                                             (click)="openMediaInNewTab(getItemThumbnail(item))">
                                    </ng-container>
                                    <ng-template #defaultIcon>
                                        <mat-icon [class]="item.type.toLowerCase() + '-icon'">
                                            {{ getItemIcon(item) }}
                                        </mat-icon>
                                    </ng-template>
                                </ng-template>
                            </div>
                            <div class="item-details">
                                <div class="item-type">{{ getItemType(item.type) }}</div>
                                <div class="campaign-name">
                                    {{ getCampaign(item.campaignId)?.name }}
                                </div>
                                <div class="item-name">{{ getItemName(item) }}</div>
                                <div class="item-preview" *ngIf="item.type === 'HOME_SCREEN'">
                                    {{ item.content.callToActionText }}
                                </div>
                            </div>
                            <button mat-icon-button color="primary" (click)="addToPlaylist(item)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                        <div *ngIf="libraryItems.length === 0" class="empty-state">
                            <mat-icon class="empty-icon">info</mat-icon>
                            <p>Create content to add to your library</p>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noCampaign>
                <div class="empty-state">
                    <mat-icon class="empty-icon">info</mat-icon>
                    <p>Select a campaign to start creating content</p>
                </div>
            </ng-template>
        </div>

        <!-- Playlist Building Area -->
        <div class="playlist-area">
            <mat-card class="playlist-card">
                <mat-card-header>
                    <mat-card-title>
                        <mat-icon>playlist_play</mat-icon>
                        Playlist
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="current-selection-info" *ngIf="selectedSegment || selectedCampaign">
                        <mat-chip-set>
                            <mat-chip *ngIf="selectedSegment" color="primary" selected>
                                <mat-icon>devices</mat-icon>
                                {{ selectedSegment.name }}
                            </mat-chip>
                            <mat-chip *ngIf="selectedCampaign" color="accent" selected>
                                <mat-icon>campaign</mat-icon>
                                {{ selectedCampaign.name }}
                            </mat-chip>
                        </mat-chip-set>
                    </div>

                    <div cdkDropList
                         id="playlistList"
                         #playlistList="cdkDropList"
                         [cdkDropListData]="playlist"
                         [cdkDropListConnectedTo]="['libraryList']"
                         class="playlist-items"
                         (cdkDropListDropped)="onDrop($event)">
                        <div *ngFor="let item of playlist; let i = index"
                             cdkDrag
                             class="playlist-item"
                             [attr.data-type]="item.type"
                             [attr.aria-label]="getItemType(item.type) + ' - ' + getItemName(item)">
                            <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
                            <div class="thumbnail-container" matTooltip="{{ getItemName(item) }}"
                                 [matTooltipShowDelay]="500">
                                <ng-container
                                    *ngIf="(item.type === 'BRAND_TAKEOVER' || item.type === 'GENERIC_VIDEO') && item.content.videoUrl; else nonVideo">
                                    <video muted class="video-player" #videoPlayer
                                           (click)="openMediaInNewTab(item.content.videoUrl)">
                                        <source [src]="item.content.videoUrl" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </ng-container>
                                <ng-template #nonVideo>
                                    <ng-container *ngIf="getItemThumbnail(item); else defaultIcon">
                                        <img [src]="getItemThumbnail(item)" alt="Thumbnail" class="thumbnail"
                                             (click)="openMediaInNewTab(getItemThumbnail(item))">
                                    </ng-container>
                                    <ng-template #defaultIcon>
                                        <mat-icon [class]="item.type.toLowerCase() + '-icon'">
                                            {{ getItemIcon(item) }}
                                        </mat-icon>
                                    </ng-template>
                                </ng-template>
                            </div>
                            <div class="item-details">
                                <div class="item-type">{{ getItemType(item.type) }}</div>
                                <div class="campaign-name">{{ getCampaign(item.campaignId)?.name }}</div>
                                <div class="item-name">{{ getItemName(item) }}</div>
                                <div class="item-preview" *ngIf="item.type === 'HOME_SCREEN'">
                                    CTA: {{ item.content.callToActionText }}
                                </div>
                            </div>
                            <button mat-icon-button color="warn" (click)="removeItem(i)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="playlist.length === 0" class="empty-playlist">
                            <mat-icon class="empty-icon">drag_indicator</mat-icon>
                            <p>Drag items from the library to build your playlist</p>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end" *ngIf="playlist.length > 0">
                    <button mat-raised-button color="primary" (click)="savePlaylist()" [disabled]="isSaving">
                        <mat-spinner *ngIf="isSaving" diameter="20"></mat-spinner>
                        <span *ngIf="!isSaving">Save Playlist</span>
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>