import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {UploadService, UploadSession} from '@looma/shared/services/upload.service';
import {takeUntil} from 'rxjs/operators';
import {Utils} from '@looma/shared/utils';
import {BrandPromoCampaign} from '@looma/shared/models/brand_promo_campaign';
import firebase from 'firebase/compat/app';
import {MatInputModule} from '@angular/material/input';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {LifecycleHooks} from '@looma/shared/lifecycle_utils';

@LifecycleHooks()
@Component({
    selector: 'app-product-spotlight-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule
    ],
    template: `
        <div class="dialog-wrapper">
            <h2 class="dialog-title" mat-dialog-title>Create Product Spotlight</h2>
            <mat-dialog-content style="padding-top: 10px">
                <form [formGroup]="form" class="content-form">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Select Product</mat-label>
                        <mat-select formControlName="product" required (selectionChange)="onProductSelected($event)">
                            <mat-option *ngFor="let product of data.campaign.featuredProducts" [value]="product">
                                {{ product.name }} - ({{ product.upc_code }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Product Name</mat-label>
                        <input matInput placeholder="Enter product name" formControlName="productName"/>
                    </mat-form-field>

                    <div class="image-section">
                        <div *ngIf="spotlightImageUrl" class="image-preview-container">
                            <div class="image-preview">
                                <img [src]="spotlightImageUrl" alt="Spotlight preview" (load)="onImageLoad($event)"/>
                            </div>
                            <div class="image-dimensions" *ngIf="imageWidth && imageHeight">
                                {{ imageWidth }} x {{ imageHeight }}
                            </div>
                        </div>
                        <button mat-raised-button
                                color="primary"
                                class="upload-button"
                                (click)="uploadSpotlightImage()"
                                [disabled]="isUploading">
                            <mat-icon>cloud_upload</mat-icon>
                            <span>{{ isUploading ? 'Uploading...' : 'Upload Custom Image' }}</span>
                        </button>
                    </div>
                </form>
            </mat-dialog-content>
            <mat-dialog-actions align="end" class="dialog-actions">
                <button mat-button (click)="onCancel()">Cancel</button>
                <button mat-raised-button
                        color="primary"
                        [disabled]="!form.valid || !spotlightImageUrl || isUploading"
                        (click)="onSave()">
                    Create Spotlight
                </button>
            </mat-dialog-actions>
        </div>
    `,
    styles: [`
        .dialog-wrapper {
            padding: 24px;
            min-width: 400px;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        }

        .dialog-title {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            margin-bottom: 16px;
        }

        mat-dialog-content {
            padding-bottom: 16px;
        }

        .content-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .full-width {
            width: 100%;
        }

        .image-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            margin-top: 16px;
        }

        .image-preview-container {
            display: inline-block;
            max-width: 480px;
            margin: 0 auto;
            position: relative;
            border-radius: 8px;
            border: 2px dashed #ccc;
            text-align: center;
            background: repeating-linear-gradient(
                0deg,
                #fff,
                #fff 24px,
                #eaeaea 25px,
                #eaeaea 26px
            ),
            repeating-linear-gradient(
                90deg,
                #fff,
                #fff 24px,
                #eaeaea 25px,
                #eaeaea 26px
            );
        }

        .image-preview {
            overflow: visible;
        }

        .image-preview img {
            display: inline-block;
            width: auto;
            max-width: 100%;
            height: auto;
        }

        .image-dimensions {
            position: absolute;
            top: 8px;
            right: 8px;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
        }

        .upload-button {
            display: flex;
            align-items: center;
            gap: 8px;
            min-width: 140px;
        }

        .dialog-actions {
            padding-top: 16px;
            border-top: 1px solid #eee;
        }
    `]
})
export class ProductSpotlightDialogComponent implements OnInit {
    form: FormGroup;
    spotlightImageUrl: string;
    isUploading = false;
    imageWidth: number;
    imageHeight: number;
    private uploadSession: UploadSession;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<ProductSpotlightDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { campaign: BrandPromoCampaign },
        private svcUpload: UploadService,
    ) {
        this.form = this.fb.group({
            product: [null, Validators.required],
            productName: [null, Validators.required],
        });

        this.uploadSession = this.svcUpload.getUploadSession('product_spotlight', {
            fileTypes: ['image'],
            multiSelection: false,
            baseUploadPath: "surveys/homescreen/product_spotlight"
        });
    }

    ngOnInit() {
        this.uploadSession.onFileUploaded()
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(file => {
                const storage = firebase.storage();
                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.spotlightImageUrl = url;
                    this.isUploading = false;
                });
            });
    }

    uploadSpotlightImage() {
        this.isUploading = true;
        this.uploadSession.openPicker();
    }

    onSave() {
        if (this.form.valid && this.spotlightImageUrl) {
            const selectedProduct = this.form.get('product')?.value;
            this.dialogRef.close({
                type: 'PRODUCT_SPOTLIGHT',
                content: {
                    productId: selectedProduct.id,
                    productName: this.form.get('productName')?.value,
                    imageUrl: this.spotlightImageUrl,
                    upcCode: selectedProduct.upc_code,
                }
            });
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

    onProductSelected(event: MatSelectChange) {
        if (event) {
            this.spotlightImageUrl = event.value.image_url;
            this.form.patchValue({productName: event.value.name});
        }
    }

    onImageLoad(event: Event) {
        const img = event.target as HTMLImageElement;
        this.imageWidth = img.naturalWidth;
        this.imageHeight = img.naturalHeight;
    }
}
