import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { appAnimations } from '@looma/shared/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Device, RemoteDeviceConfigMutationData } from '@looma/shared/models/device';
import { debounceTime, filter, flatMap, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ApiDataService, DeviceUpdateData } from '../../../../services/api-data.service';
import { QueryRef } from 'apollo-angular';
import { LayoutService } from '../../../../services/layout.service';
import { Location } from '@angular/common';
import { LoomaColor } from '@looma/shared/colors';
import { isMobileBrowser, Utils } from '@looma/shared/utils';
import { Retailer } from '@looma/shared/models/retailer';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { ProductCategory } from '@looma/shared/models/product_category';
import { RetailerRegion } from '@looma/shared/models/retailer_region';
import { Store } from '@looma/shared/models/store';
import { DevicePowerCycleLog } from '@looma/shared/models/device_power_cycle_log';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { COMMANDS, DeviceCommand, getCommand, KioskModeCommand } from '@looma/shared/types/device_commands';
import { DeviceCommandService } from '../../../../services/device-command.service';
import { DeviceCommandBundleResult } from '@looma/shared/models/device_command_bundle_result';
import { ApiResponse } from '@looma/shared/models/api_response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LifecycleHooks } from "@looma/shared/lifecycle_utils";
import {
    MUTATION_MUTATE_REMOTE_DEVICE_CONFIG,
    MUTATION_SET_ACCELEROMETER_THRESHOLD
} from "../../../../services/queries";
import { DeviceVolumeDialogComponent } from "../device-volume-dialog/device-volume-dialog.component";
import {
    AddImageDialogComponent
} from "../../../device-slot-images/components/add-image-dialog/add-image-dialog.component";
import { GoogleMap } from "@angular/google-maps";
import { LoomaAuthService } from "@looma/shared/auth/components/services/looma-auth.service";
import { DeviceUserRight } from "@looma/shared/models/user_right";
import Timeout = NodeJS.Timeout;

interface ColorScheme{
    colors: Array<{ value: number, color: string }>;
    minValue?: number;
    maxValue?: number;
}

const WifiColorScheme: ColorScheme = {
    colors: [ {
        value: 0.5,
        color: '#e0e0e0'
    }, {
        value: 1.5,
        color: '#bdbdbd'
    }, {
        value: 2.5,
        color: '#9e9e9e'
    }, {
        value: 3.5,
        color: '#757575'
    }, {
        value: 4.5,
        color: '#616161',
    } ],

    minValue: 0.5,
    maxValue: 5.5
};

const BatteryColorScheme: ColorScheme = {
    colors: [ {
        value: 0,
        color: LoomaColor.Red
    }, {
        value: 25,
        color: LoomaColor.Orange
    }, {
        value: 50,
        color: LoomaColor.Yellow
    }, {
        value: 75,
        color: LoomaColor.Blue
    } ],
    minValue: 0,
    maxValue: 100
};

const VolumeColorScheme: ColorScheme = {
    colors: [ {
        value: 0,
        color: LoomaColor.Red
    }, {
        value: 0.1,
        color: LoomaColor.Yellow
    }, {
        value: .25,
        color: LoomaColor.Green
    }, {
        value: 0.5,
        color: LoomaColor.Orange
    } ],
    minValue: 0,
    maxValue: 1

};


const DeviceStatusScheme: ColorScheme = {
    colors: [
        { value: -600, color: LoomaColor.Green },
        { value: -3600, color: LoomaColor.Yellow },
        { value: -3600 * 3, color: LoomaColor.Orange },
        { value: -3600 * 3 + 300, color: LoomaColor.Red },
    ],

    maxValue: 0,
    minValue: -3600 * 3 + 300
};

const DeviceHeartbeatScheme: ColorScheme = {
    colors: [
        { value: -600, color: LoomaColor.Green },
        { value: -3600, color: LoomaColor.Yellow },
        { value: -3600 * 3, color: LoomaColor.Orange },
        { value: -3600 * 3 + 300, color: LoomaColor.Red },
    ],

    maxValue: 0,
    minValue: -3600 * 3 + 300
};

function getColorStops(ranges: Array<{
    value: number,
    color: string
}>, minValue?: number, maxValue?: number): Array<Array<any>>{
    ranges = Utils.cloneObject(ranges).sort(a => a.value);

    const min = isNaN(minValue) ? ranges[0].value : minValue,
        max = isNaN(maxValue) ? ranges[ranges.length - 1].value : maxValue, delta = 0;
    const colorStops = [];

    for (let i = 1; i < ranges.length; i++) {
        const entry = ranges[i];

        const percent = Math.abs((entry.value - min) / (max - min));
        colorStops.push([ percent, ranges[i - 1].color ]);
    }

    colorStops.push([ 1, ranges[ranges.length - 1].color ]);
    return colorStops;
}


@LifecycleHooks()
@Component({
    selector: 'app-device-details',
    templateUrl: './device-details.component.html',
    styleUrls: [ './device-details.component.scss' ],
    animations: appAnimations,
})
export class DeviceDetailsComponent implements OnInit, OnDestroy{

    @ViewChild('deviceMap', { static: true }) deviceMap: GoogleMap;

    mapOptions: google.maps.MapOptions = {
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
    }

    deviceHistoryMetrics: string[] = [
        'PlaybackStatus',
        'HeartbeatStatus'
    ]

    constructor(
        private svcApi: ApiDataService,
        private router: Router,
        private route: ActivatedRoute,
        private svcLayout: LayoutService,
        private snackbar: MatSnackBar,
        public location: Location,
        private svcDeviceCommand: DeviceCommandService,
        private svcAuth: LoomaAuthService
    ){
        this.isMobile = isMobileBrowser()

        this.availableDeviceCommands = COMMANDS.filter(cmd => {
            return this.hasRight(cmd.userRight) && cmd.showInMenu !== false
        });
    }


    chartOptions: Map<string, any> = new Map();
    chartInstances: Map<string, any> = new Map();
    device: Device;

    queryRef: QueryRef<Response>;
    refetchTimeout: Timeout;
    redrawTimout: NodeJS.Timer;
    availableDeviceCommands: DeviceCommand[]

    private notificationSub: Subscription;

    private devicePowerLogsDataSource: PowerCycleDataSource;
    private deviceCommandResultsDataSource: DeviceCommandResultsDataSource;

    remoteDeviceConfigChanges: Partial<RemoteDeviceConfigMutationData> = {}

    isMobile = false

    DeviceUserRight = DeviceUserRight

    hasRight(right: string): boolean{
        return this.svcAuth.currentUser?.hasRight(right)
    }

    ngOnInit(): void{
        const deviceId = this.route.snapshot.paramMap.get('id');
        this.queryRef = this.svcApi.queryDevice(deviceId);


        this.queryRef.valueChanges.pipe(
            takeUntil(Utils.onDestroy(this)),
            map(result => {
                const devices = Utils.getNestedTypedArray(result, Device, 'data', 'devices', 'device');
                if (Array.isArray(devices)) {
                    return devices[0]
                }
                return new Device().assign({ device_id: deviceId })
            })
        ).subscribe(value => {
            this.device = value;

            if (value.remote_playback_info) {
                this.setChartValue('device_status', -value.remote_playback_info.session_updated_ago);
            }
            if (value.remote_device_info) {
                this.setChartValue('heartbeat_status', -value.remote_device_info.last_heartbeat_ago);
                this.setChartValue('volume_status', value.remote_device_info.adjusted_volume);
            }

            if (value.remote_realtime_stats) {
                this.setChartValue('up_time_status', value.remote_realtime_stats.up_time, value.remote_realtime_stats.down_time);
                this.setChartValue('playback_time_status', value.remote_realtime_stats.playback_time, value.remote_realtime_stats.playback_down_time);
            }

            if (value.remote_wifi_info) {
                this.setChartValue('wifi_status', value.remote_wifi_info.signal_level);
            } else {
                this.setChartValue('wifi_status', undefined);
            }

            if (value.remote_battery_info) {
                this.setChartValue('battery_status', value.remote_battery_info.fill);
            } else {
                this.setChartValue('battery_status', undefined);
            }

            this.refetchTimeout = setTimeout(() => {
                this.queryRef.refetch();
            }, 60000);

            this.listenForNotifications()

            this.mapOptions = {
                ...this.mapOptions,
                center: {
                    lat: this.device.remote_location_info.lat,
                    lng: this.device.remote_location_info.lng,
                },
                zoom: 15
            }

        });
    }
    

    formatBytes(bytes: number): string{
        if (!bytes){
            return '-'
        }
        const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];

        const i = Math.floor(Math.log2(bytes) / 10); // Using log2 to determine the order of magnitude

        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
    }

    openAddImageDialog(){
        const devSlot = this.device?.assignation
        if (!devSlot?.id) {
            return
        }
        AddImageDialogComponent.open(this.svcLayout, {
            deviceSlot: devSlot,
            hideDeviceSlotSelectionField: true,
            hideDateField: true,
        }).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
        })
    }

    private listenForNotifications(): void{
        if (!this.notificationSub && this.device && this.device.id) {
            this.notificationSub = this.svcDeviceCommand.onDeviceCommandCompleted(this.device.id).pipe(
                takeUntil(Utils.onDestroy(this)),
                debounceTime(1000)
            ).subscribe(notificationCmd => {
                this.queryRef.refetch();
                if (this.deviceCommandResultsDataSource) {
                    this.deviceCommandResultsDataSource.reload();
                }
            });
        }

    }

    invokeCommand(cmd: DeviceCommand): void{
        this.svcLayout.confirm('Sending command', cmd.confirmationMessage(null))
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(value => {
                if (value) {
                    this.svcDeviceCommand.sendDeviceCommand(this.device.id, cmd)
                }
            });
    }

    unassignDevice(): void{

        this.svcLayout.confirm('Unassign Device', 'Are you sure you want to unassign this device?').pipe(
            filter(value => value),
            flatMap(value => {
                return this.svcApi.unassignDevice(this.device)
            }),
        ).subscribe((resp: ApiResponse) => {
            if (resp.success) {
                this.queryRef.refetch();
            } else {
                this.snackbar.open(resp.message, null, { duration: 5000 })
            }
        });

    }

    replaceDevice(): void{
        this.svcLayout.prompt('Replace Device', 'Replacement Device Id').pipe(
            take(1),
            flatMap(value => {
                return this.svcApi.replaceDevice(this.device, value.toString())
            }),
        ).subscribe((resp: ApiResponse) => {
            if (resp.success) {
                this.queryRef.refetch();
                this.snackbar.open('Device was replaced', null, { duration: 5000 })
            } else {
                this.snackbar.open(resp.message, null, { duration: 5000 })
            }
        });
    }

    resetDevicePosition(): void{
        this.svcLayout.confirm('Reset Device Position', 'Are you sure you want to reset the position?')
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(value => {
                if (value) {
                    this.svcDeviceCommand.sendDeviceCommand(this.device.id, getCommand('reset_device_position'))
                }
            });
    }

    changeDeviceCalibrationThreshold(): void{
        this.svcLayout.prompt({
            type: "number",
            title: 'Alert Threshold',
            message: 'Value needs to be between 0.1 and 1'
        }).pipe(
            flatMap(value => {
                const f = parseFloat(value)
                if (isNaN(f)) {
                    return EMPTY;
                }
                if (f < 0.1 || f > 1) {
                    this.svcLayout.showSnackMessage("Invalid value - needs to be between 0.1 and 1")
                    return EMPTY
                }
                return this.svcApi.rawObjectMutate(MUTATION_SET_ACCELEROMETER_THRESHOLD, {
                    deviceId: this.device.id,
                    value: f
                }, Device)

            })
        ).subscribe(value => {
            if (value.success) {
                this.device.remote_accelerometer_info.calibration_threshold = value.data.remote_accelerometer_info.calibration_threshold
                this.svcLayout.showSnackMessage("Alert threshold update")
            } else {
                this.svcLayout.showMutationResultMessage(value)
            }
        }, error => {
            console.error(error)
            this.svcLayout.showSnackMessage('Unexpected error')
        });
    }

    setKioskModeEnabled(enabled: boolean): void{
        const mgs = enabled ? 'Are you sure you want to turn ON kiosk mode?' : 'Are you sure you want to turn OFF kiosk mode?';
        this.svcLayout.confirm('Kiosk Mode' + (enabled ? 'On' : 'Off'), mgs)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(value => {
                if (value) {
                    this.svcDeviceCommand.sendDeviceCommand(this.device.id, KioskModeCommand, enabled ? 'Yes' : 'No')
                }
            });
    }

    setInstalled(installed: boolean): void{
        const mgs = installed ? 'Are you sure you want to install this device?' : 'Are you sure you want to uninstall this device?';
        this.svcLayout.confirm(installed ? 'Install device' : 'Uninstall device ', mgs).subscribe(value => {
            if (value) {
                const data = new DeviceUpdateData();
                data.is_installed = installed;
                this.svcApi.updateDevice(this.device, data)
                    .pipe(takeUntil(Utils.onDestroy(this)))
                    .subscribe(v => {
                        this.queryRef.refetch();
                    })
            }
        });

    }

    ngOnDestroy(): void{
        clearTimeout(this.refetchTimeout);
        clearTimeout(this.redrawTimout);
        this.queryRef.stopPolling();
    }


    getEchartsOptions(section: string): any{
        if (!this.chartOptions.has(section)) {
            const opt = this.initEchartsOptions(section);
            if (!opt) {
                return opt;
            }
            this.chartOptions.set(section, opt);
        }
        return this.chartOptions.get(section);
    }

    onChartInit(e: any, section: string): void{
        this.chartInstances.set(section, e);
    }

    private setChartValue(section: string, ...values: number[]): void{
        const opt = this.getEchartsOptions(section);
        if (!opt) {
            return;
        }
        const series = opt.series;
        let changed = false;
        if (Array.isArray(series) && series.length) {
            const data = series[0].data;
            if (Array.isArray(data)) {
                for (let i = 0; i < Math.min(values.length, data.length); i++) {
                    if (data[i].hasOwnProperty('value')) {
                        data[i].value = values[i];
                        changed = true;
                    }
                }
            }

        }
        if (changed) {
            const chart = this.chartInstances.get(section);
            if (chart) {
                chart.setOption(opt);
            }
        }
    }


    initEchartsOptions(section: string): any{
        switch (section) {
            case 'device_status':
                return {
                    animation: false,
                    backgroundColor: 'white',
                    series: [
                        {
                            name: 'Device Status',
                            type: 'gauge',
                            data: [ { value: DeviceStatusScheme.minValue, name: 'Uptime' } ],
                            startAngle: 220,
                            endAngle: -40,
                            min: DeviceStatusScheme.minValue,
                            max: DeviceStatusScheme.maxValue,
                            axisTick: {
                                show: false,
                            },
                            title: {
                                show: false
                            },
                            axisLine: {
                                lineStyle: {
                                    color: getColorStops(DeviceStatusScheme.colors, DeviceStatusScheme.minValue, DeviceStatusScheme.maxValue),
                                    width: 10
                                }
                            },
                            axisLabel: {
                                show: false
                            },
                            splitLine: {
                                show: false,
                            },
                            detail: {
                                show: false,

                            },
                            pointer: {
                                width: 5,
                                length: '90%',
                                color: 'rgba(255, 255, 255,1)'
                            },
                        }
                    ]
                };

            case 'battery_status':
            case 'wifi_status':
            case 'volume_status':
            case 'heartbeat_status':

                let scheme: ColorScheme = null;
                switch (section) {
                    case 'battery_status':
                        scheme = BatteryColorScheme;
                        break;
                    case 'wifi_status':
                        scheme = WifiColorScheme;
                        break;
                    case 'volume_status':
                        scheme = VolumeColorScheme;
                        break;
                    case 'heartbeat_status':
                        scheme = DeviceHeartbeatScheme;
                        break;
                }

                if (!scheme) {
                    return undefined;
                }

                return {
                    backgroundColor: 'white',
                    series: [
                        {
                            min: scheme.minValue,
                            max: scheme.maxValue,
                            type: 'gauge',
                            data: [ { value: scheme.minValue, name: '' } ],
                            startAngle: 180,
                            endAngle: -0,
                            axisTick: {
                                show: false,

                            },
                            title: {
                                show: false
                            },
                            axisLine: {
                                lineStyle: {
                                    color: getColorStops(scheme.colors, scheme.minValue, scheme.maxValue),
                                    width: 5
                                }
                            },
                            axisLabel: {
                                show: false
                            },
                            splitLine: {
                                show: false,
                                length: 10,

                            },
                            detail: {
                                show: false,
                            },
                            pointer: {
                                width: 3,
                                length: '90%',
                                color: 'rgba(255, 255, 255,1)'
                            },
                        }
                    ]
                };


            case 'up_time_status':
            case 'playback_time_status':
                return {
                    series: [
                        {
                            type: 'pie',
                            radius: [ '60%', '70%' ],
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '15',
                                        fontWeight: 'bold'
                                    },
                                    formatter: '{d}%'
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: true
                                }
                            },
                            data: [
                                { value: 0, name: 'Up Time', itemStyle: { color: '#2196f3' } },
                                { value: 100, name: 'Down Time', itemStyle: { color: 'rgba(0,0,0,0.1)' } },

                            ]
                        }
                    ]
                };

            default:
                return undefined;
        }
    }


    getDevicePowerCycleLogsDataSource(): PowerCycleDataSource{
        if (!this.devicePowerLogsDataSource) {
            this.devicePowerLogsDataSource = new PowerCycleDataSource(this.svcApi, this.route.snapshot.paramMap.get('id'))
        }
        return this.devicePowerLogsDataSource
    }

    getDeviceResultsDataSource(): DeviceCommandResultsDataSource{
        if (!this.deviceCommandResultsDataSource) {
            this.deviceCommandResultsDataSource = new DeviceCommandResultsDataSource(this.svcApi, this.device.id);
        }
        return this.deviceCommandResultsDataSource;
    }

    getFileName(url: string): string{
        let rawName = '';
        if (url) {
            rawName = decodeURIComponent(url.split('/').pop());
            rawName = rawName.split('/').pop();
            const qsPos = rawName.indexOf('?');
            if (qsPos > 0) {
                rawName = rawName.substr(0, qsPos)
            }

        }
        return rawName
    }

    navigateToEdit(): void{
        this.router.navigate([ `/devices/${this.route.snapshot.paramMap.get('id')}/edit` ])
    }

    navigateToRemoteControl(): void{
        this.router.navigate([ `/devices/${this.route.snapshot.paramMap.get('id')}/remote_control` ])
    }

    formatDeviceVolumePercent(value: number){
        if (isNaN(value)) {
            return "-"
        }
        return Math.round(value * 100) + '%';
    }

    changeDeviceVolume(){
        DeviceVolumeDialogComponent.open(this.svcLayout, this.device).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(wasUpdated => {
            if (wasUpdated) {
                this.queryRef.refetch();
            }
        })
    }


    setRemoteConfigFieldValue(field: string, value: any){
        switch (field) {
            case 'auto_reboot_enabled':
                if (Utils.isBoolean(value)) {
                    this.remoteDeviceConfigChanges.autoRebootEnabled = value
                }
                break
            case 'env_switch_enabled':
                if (Utils.isBoolean(value)) {
                    this.remoteDeviceConfigChanges.envSwitchEnabled = value
                }
                break
            case 'volume_tiers_enabled':
                if (Utils.isBoolean(value)) {
                    this.remoteDeviceConfigChanges.volumeTiersEnabled = value
                }
                break
        }
    }

    get hasRemoteConfigChanges(): boolean{
        return Object.keys(this.remoteDeviceConfigChanges).length > 0
    }

    get isDeviceConfigurable(): boolean{
        return false
    }

    saveRemoteConfig(){
        const changes = this.remoteDeviceConfigChanges
        this.remoteDeviceConfigChanges = {}
        this.svcLayout.onConfirmed('Change Device Config', `Are you sure you want to change the device config?`).pipe(
            flatMap(_ => {
                return this.svcApi.rawObjectMutate(MUTATION_MUTATE_REMOTE_DEVICE_CONFIG, {
                    deviceId: this.device.id,
                    data: changes
                }, Device)
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.svcLayout.showSnackMessage("Device config updated")
                console.warn('remoteDeviceConfig', value.data.remoteDeviceConfig)
            } else {
                this.svcLayout.showMutationResultMessage(value)
                this.remoteDeviceConfigChanges = changes
            }
        })

    }

    stringifyDeviceAssignation(device: Device){
        const assignation = device?.assignation
        if (!assignation) {
            return null
        }
        return [
            assignation.store?.retailer?.retailer_name?.toLowerCase(),
            assignation.store.retailer_region.region_name,
            assignation.product_category.category_name,
            assignation.store.retailer_store_num
        ].join('-')
    }

}


interface DeviceAssignation{
    retailer?: Retailer;
    region?: RetailerRegion;
    productCategory?: ProductCategory;
    store?: Store;
    deviceNumber?: number;
}

class PowerCycleDataSource extends DataSource<DevicePowerCycleLog>{
    hasData = true;

    constructor(private svcApiService: ApiDataService, private deviceId: string){
        super();
    }

    connect(): Observable<DevicePowerCycleLog[]>{
        return this.svcApiService.queryDevicePowerCycleLogs(this.deviceId).pipe(
            tap(x => this.hasData = x.length > 0)
        )
    }

    disconnect(collectionViewer: CollectionViewer): void{

    }
}

class CommandEntry{

    cmdName: string;
    cmdArgs: string;
    issuedByUser: string;
    issuedAt: string;
    expiresAt: string;
    statusName: string;
    message: string;
    attachmentUrl: string;

    assign(res: DeviceCommandBundleResult): this | null{

        if (!res.device_results || (res.device_results.length !== 1)) {
            return null
        }


        this.cmdName = res.displayName;
        this.cmdArgs = res.cmd_args;
        this.issuedByUser = res.user && res.user.display_name || '';
        this.issuedAt = res.created_at.toLocaleString();
        this.expiresAt = res.expires_at.toLocaleString();

        const deviceRes = res.device_results[0];

        this.statusName = DeviceCommandBundleResult.getStatusName(deviceRes.status);
        this.message = deviceRes.message;
        if (deviceRes.attachment) {
            this.attachmentUrl = deviceRes.attachment.download_url;
        }

        return this
    }
}

class DeviceCommandResultsDataSource extends DataSource<CommandEntry>{

    hasData = true;
    private dataPipe: Subject<CommandEntry[]> = new Subject();
    private loadSub: Subscription;
    private loadedData: CommandEntry[];

    constructor(private svcApiService: ApiDataService, private deviceId: string){
        super();
    }

    connect(): Observable<CommandEntry[]>{
        if (!this.loadedData) {
            this.reload();
            return this.dataPipe;
        } else {
            return this.dataPipe.pipe(
                startWith(this.loadedData)
            )
        }
    }

    private readItems(items: DeviceCommandBundleResult[]): CommandEntry[]{
        return items.reduce((accum, item) => {
            const x = new CommandEntry().assign(item);
            if (x) {
                accum.push(x)
            }
            return accum
        }, [])
    }

    reload(): void{
        Utils.unsubscribe(this.loadSub);
        this.loadSub = this.svcApiService.getCommandResult(undefined, this.deviceId).pipe(
            map(this.readItems),
            tap(x => this.hasData = x.length > 0)
        ).subscribe(value => {
            this.loadedData = value;
            this.dataPipe.next(value);
        })
    }


    disconnect(collectionViewer: CollectionViewer): void{
    }
}

