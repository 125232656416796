import {Component, OnInit} from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {UploadService, UploadSession} from '@looma/shared/services/upload.service';
import {takeUntil} from 'rxjs/operators';
import {Utils} from '@looma/shared/utils';
import firebase from 'firebase/compat/app';
import {ToastNotificationService} from '../../../../services/toast-notification.service';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {LifecycleHooks} from '@looma/shared/lifecycle_utils';

@LifecycleHooks()
@Component({
    selector: 'app-brand-takeover-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressBarModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        FormsModule
    ],
    template: `
        <div class="dialog-wrapper">
            <h2 class="dialog-title" mat-dialog-title>Create Brand Takeover</h2>
            <mat-dialog-content style="padding-top: 10px">
                <div class="video-section">
                    <div *ngIf="videoUrl" class="video-preview-container">
                        <div class="video-preview">
                            <video [src]="videoUrl" controls></video>
                        </div>
                    </div>
                    <button mat-stroked-button
                            type="button"
                            class="upload-button"
                            (click)="uploadVideo()"
                            [disabled]="isUploading">
                        <mat-icon>cloud_upload</mat-icon>
                        <span>{{ isUploading ? 'Uploading...' : 'Upload Video' }}</span>
                    </button>
                    <mat-progress-bar *ngIf="isUploading"
                                      mode="indeterminate"
                                      class="upload-progress">
                    </mat-progress-bar>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions align="end" class="dialog-actions">
                <button mat-button (click)="onCancel()">Cancel</button>
                <button mat-flat-button
                        color="primary"
                        [disabled]="!videoUrl || isUploading"
                        (click)="onSave()">
                    Create Takeover
                </button>
            </mat-dialog-actions>
        </div>
    `,
    styles: [`
        .dialog-wrapper {
            padding: 24px;
            min-width: 400px;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        }

        .dialog-title {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            margin-bottom: 16px;
        }

        mat-dialog-content {
            padding-bottom: 16px;
        }

        .full-width {
            width: 100%;
            margin-bottom: 16px;
        }

        .video-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            margin: 20px 0;
        }

        .video-preview-container {
            width: 100%;
            max-width: 480px;
            margin: 0 auto;
            background: #f5f5f5;
            border-radius: 8px;
            padding: 16px;
        }

        .video-preview {
            position: relative;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            background: #000;
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }

        .video-preview video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: #000;
        }

        .upload-button {
            display: flex;
            align-items: center;
            gap: 8px;
            min-width: 140px;
        }

        .upload-progress {
            width: 100%;
            max-width: 480px;
        }

        .dialog-actions {
            padding-top: 16px;
            border-top: 1px solid #eee;
        }
    `]
})
export class BrandTakeoverDialogComponent implements OnInit {
    itemName = '';
    videoUrl: string;
    isUploading = false;
    private uploadSession: UploadSession;

    constructor(
        public dialogRef: MatDialogRef<BrandTakeoverDialogComponent>,
        private svcUpload: UploadService,
        private svcToastNotif: ToastNotificationService
    ) {
        this.uploadSession = this.svcUpload.getUploadSession('brand_takeover', {
            fileTypes: ['video'],
            multiSelection: false,
            baseUploadPath: "surveys/homescreen/brand_takeover"
        });
    }

    ngOnInit() {
        this.uploadSession.onFileAdded()
            .pipe(takeUntil(Utils.onDestroy(this))).subscribe((file) => {
            this.isUploading = true;
        })

        this.uploadSession.onFileUploaded()
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe((file) => {
                const storage = firebase.storage();
                storage.ref(file.firebaseKey).getDownloadURL().then(url => {
                    this.videoUrl = url;
                    this.isUploading = false;
                });
            });
    }

    uploadVideo() {
        this.uploadSession.openPicker();
    }

    onSave() {
        if (this.videoUrl) {
            this.dialogRef.close({
                type: 'BRAND_TAKEOVER',
                itemName: this.itemName,
                content: {
                    videoUrl: this.videoUrl,
                }
            });
        }
    }

    onCancel() {
        this.dialogRef.close();
    }
}
