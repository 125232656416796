<div class="status-container">
    <div class="controls-wrapper">
        <mat-form-field appearance="fill">
            <mat-label>Select Metric</mat-label>
            <mat-select [formControl]="selectedMetric">
                <mat-option *ngFor="let metric of metrics" [value]="metric">
                    {{ metric }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="picker" [max]="today" [formControl]="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="status-header">
        <span class="status-name">{{ selectedMetricName }}</span>
        <span class="status-badge" [style.backgroundColor]="getOperationColor">
            {{ isOperational 
                ? 'Operational' 
                : hasData 
                    ? 'Issues Detected'
                    : 'No Data' }}
        </span>
    </div>
    <div class="chart-wrapper">
        <div class="tick-container">
            <div *ngFor="let tick of filteredData; index as i"
                class="status-tick"
                [style.backgroundColor]="getTickColor(tick)"
                [matTooltip]="getTooltipText(tick, i)"
                matTooltipPosition="above">
            </div>
        </div>
    </div>
    <div class="status-footer">
        <span>{{ getStartDate }}</span>
        <span>{{ getUptimePercentage }}%</span>
        <span>{{ getEndDate }}</span>
    </div>
</div>