import {ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {PromoScheduleEditDialogComponent} from "../promo-schedule-edit-dialog/promo-schedule-edit-dialog.component";
import {GridRowEditor, LayoutService} from "../../../../services/layout.service";
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {BaseCursorLoader, CursorDataProvider} from "@looma/shared/cursor_loader";
import {ApiDataService} from "../../../../services/api-data.service";
import {EMPTY, Observable, Subject} from "rxjs";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {debounceTime, filter, flatMap, map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {DynamicSizeVirtualScrollStrategy} from "../../../../layout/components/looma-grid/looma-grid.component";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {Overlay} from "@angular/cdk/overlay";
import {SearchableField, SearchFieldCriteria} from "@looma/shared/search";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NamedValue} from "@looma/shared/types/named_value";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {BaseModel} from "@looma/shared/models/base_model";
import {
    PromoScheduleProgramOverviewComponent
} from "../promo-schedule-program-overview/promo-schedule-program-overview.component";
import {Router} from "@angular/router";
import {
    PromoPeriodPlaylistsOverviewComponent
} from "../promo-period-playlists-overview/promo-period-playlists-overview.component";
import gql from "graphql-tag";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {RetailerPromoPeriod, RetailerPromoPeriodMutationInput} from "@looma/shared/models/retailer_promo_periods";
import {SegmentEditDialogComponent} from "../segment-edit-dialog/segment-edit-dialog.component";
import {DeviceSlotSegment, DeviceSlotSegmentInput} from "@looma/shared/models/device_slot_segment";
import {RowActionRegistry} from "../../../../shared/row_action_registry";
import {ButtonActions} from "../../../../shared/button_actions";
import {
    SegmentDeviceSlotAssignmentComponent
} from "../segment-device-slot-assignment/segment-device-slot-assignment.component";
import {SegmentCopyDialogComponent} from "../segment-copy-dialog/segment-copy-dialog.component";
import {Retailer} from "@looma/shared/models/retailer";
import {ScheduleTabControllerService} from "../promo-schedules-list/promo-schedules-list.component";
import {DeviceApp} from "@looma/shared/models/device_app";
import {
    PromoPeriodSurveyOverviewComponent
} from "../promo-period-survey-overview/promo-period-survey-overview.component";
import {
    ProgramSubmissionsOverviewComponent
} from "../program-submissions-overview/program-submissions-overview.component";
import {
    HomescreenPlaylistComponentComponent
} from "../homescreen-playlist-component/homescreen-playlist-component.component";


@LifecycleHooks()
@Component({
    selector: 'app-promo-schedules-for-retailer-list',
    templateUrl: './promo-schedules-for-retailer-list.component.html',
    styleUrls: ['./promo-schedules-for-retailer-list.component.scss']
})
export class PromoSchedulesForRetailerListComponent implements OnInit {

    private _retailer: Retailer;

    readonly LoopPlayerApp = DeviceApp.LoopPlayerApp
    readonly SurveyApp = DeviceApp.SurveyApp

    constructor(
        public svcApi: ApiDataService,
        public svcLayout: LayoutService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        public fb: FormBuilder,
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private svcTabs: ScheduleTabControllerService
    ) {
        this.schedulesLoader = new PromoSchedulesLoader(this, this.filter.pipe(
            takeUntil(Utils.onDestroy(this)),
            debounceTime(100),
        ));

        this.schedulesDataProvider = new CursorDataProvider();
        this.schedulesDataProvider.setLoader(this.schedulesLoader);
    }


    @Input() set retailer(v: Retailer) {
        this._retailer = v;
        this.schedulesLoader.setRetailer(v);
    }

    get retailer(): Retailer {
        return this._retailer;
    }


    @ViewChild(CdkVirtualScrollViewport, {static: true}) set scrollViewport(viewport: CdkVirtualScrollViewport) {
        const scrollStrategy = new DynamicSizeVirtualScrollStrategy(this.itemSize, 1, 1);
        scrollStrategy.onRenderRangeChanged().pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            const maxIndex = this.schedulesDataProvider.loadedData.length;
            if (value.end >= maxIndex - 1) {
                this.schedulesDataProvider.next();
            }
        });

        viewport['_scrollStrategy'] = scrollStrategy;
    }

    schedulesLoader: PromoSchedulesLoader;
    schedulesDataProvider: CursorDataProvider<RetailerPromoSchedule>;

    private periodsDataSources = new Map<string, PromoPeriodsDataSource>();
    private segmentsDataSource = new Map<string, SegmentDataSource>();

    itemSize = 40;

    private filter = new Subject<RetailerPromoScheduleFeedFilter>();

    @ViewChild('tplEditPromoPeriod') tplEditPromoPeriod: TemplateRef<any>;

    private usedPromoPrograms = new Map<string, RetailerPromoProgram[]>()

    segmentActions = new RowActionRegistry<DeviceSlotSegment>([
        {
            key: ButtonActions.Edit,
            label: 'Edit',
            handler: item => {
                return this.showEditSegmentDialogBox(item.deviceSlotType.retailerPromoProgram, item.promoPeriod, item)
            },
            available: item => {
                return item.promoPeriod?.isPending
            }
        }, {
            key: ButtonActions.Delete,
            label: 'Delete',
            handler: item => {
                return this.performDeviceSlotSegmentDelete(item);
            },
            available: item => {
                return item.promoPeriod?.isPending
            }
        }
    ]);

    ngOnInit() {
        this.schedulesDataProvider.next()
        this.svcTabs.setActiveTab(this);
    }

    editSchedule(sch: RetailerPromoSchedule) {
        PromoScheduleEditDialogComponent.open(this.svcLayout, sch, SCHEDULE_GQL_FIELDS).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(sch => {
            if (sch) {
                this.schedulesDataProvider.replaceOrPrepend(sch);
                this.periodsDataSources.delete(sch.id);
            }
        })
    }

    deleteSchedule(sch: RetailerPromoSchedule) {
        if (!this.canDeleteSchedule(sch)) {
            return
        }
        this.svcLayout.confirm('Delete Schedule', `Are you sure you want to delete ${sch.name} ?`).pipe(
            filter(value => !!value),
            flatMap(value => {
                return this.svcApi.mutatePromoSchedule(MutationOperation.Delete, {id: sch.getStringId()}, "id")
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.schedulesDataProvider.remove(sch)
            }
        })
    }

    canDeleteSchedule(sch: RetailerPromoSchedule): boolean {
        return (sch.promoPeriods || []).length == 0;
    }

    createNewSchedule() {
        const sch = new RetailerPromoSchedule();
        sch.retailer = this.retailer;
        this.editSchedule(sch);
    }

    getPromoPeriodsDataSource(sch: RetailerPromoSchedule): PromoPeriodsDataSource {
        if (!this.periodsDataSources.has(sch.id)) {
            this.periodsDataSources.set(sch.id, new PromoPeriodsDataSource(sch, this))
        }
        return this.periodsDataSources.get(sch.id);
    }

    getSegmentsDataSource(promoPeriod: RetailerPromoPeriod): SegmentDataSource {
        if (!this.segmentsDataSource.has(promoPeriod.id)) {
            this.segmentsDataSource.set(promoPeriod.id, new SegmentDataSource(promoPeriod))
        }
        return this.segmentsDataSource.get(promoPeriod.id);
    }

    addPromoPeriod(sch: RetailerPromoSchedule) {
        const promoPeriod = RetailerPromoPeriod.newPending();
        this.getPromoPeriodsDataSource(sch).addItem(promoPeriod, false);
        this.editPeriod(sch, promoPeriod)
    }

    showPeriodPlaylists(sch: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod) {
        PromoPeriodPlaylistsOverviewComponent.open(this.router, sch, promoPeriod);
    }

    canDeletePeriod(sch: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod): boolean {
        const ds = this.getPromoPeriodsDataSource(sch), count = ds.getItemCount(),
            idx = ds.getItemPosition(promoPeriod);
        if (idx < 0) {
            return false
        }
        if (promoPeriod.isActive) {
            return false
        }
        return idx == count - 1

    }

    deletePeriod(sch: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod) {
        promoPeriod = BaseModel.unwrap(promoPeriod);
        sch = BaseModel.unwrap(sch);

        if (!promoPeriod || !sch) {
            return
        }

        this.svcLayout.onConfirmed('Delete Promo Period', `Are you sure you want to delete ${promoPeriod.name}? `).pipe(
            flatMap(_ => {
                return this.svcApi.mutatePromoPeriod(MutationOperation.Delete, {
                    id: promoPeriod.getStringId()
                })
            })
        ).subscribe(value => {
            if (value.success) {
                this.getPromoPeriodsDataSource(sch).removeItem(promoPeriod)
            } else {
                this.svcLayout.showSnackMessage(value.message || 'Unexpected Delete Error')
            }

        })
    }

    getUsedPromoPrograms(promoPeriod: RetailerPromoPeriod) {
        promoPeriod = BaseModel.unwrap(promoPeriod);

        if (promoPeriod.isNewRecord()) {
            return []
        }
        const key = promoPeriod.id;

        if (!this.usedPromoPrograms.has(key)) {
            const usedPrograms = [];
            for (const promoProgram of (promoPeriod.promoPrograms)) {
                usedPrograms.push(promoProgram)
            }
            this.usedPromoPrograms.set(key, usedPrograms);
        }
        const program = this.usedPromoPrograms.get(key);
        return program;
    }

    editPeriod(sch: RetailerPromoSchedule, promoPeriod: RetailerPromoPeriod) {

        promoPeriod = promoPeriod.unwrap();
        promoPeriod.promoSchedule = BaseModel.unwrap(sch);
        const orig = new RetailerPromoPeriod();
        orig.id = promoPeriod.id;


        const rowEditor = new PromoPeriodRowEditor(promoPeriod, sch, this, SearchFieldCriteria.newEqualsCriteria(SearchableField.RetailerId, sch.retailer.id));

        this.svcLayout.openRowEditor(
            this.getPromoPeriodsDataSource(sch),
            promoPeriod,
            this.tplEditPromoPeriod,
            this.viewContainerRef,
            rowEditor,
        ).subscribe(value => {
            if (value) {
                this.usedPromoPrograms.delete(value.id)
            }

            if (orig.isNewRecord()) {
                this.getPromoPeriodsDataSource(sch).removeItem(promoPeriod)
            }
            if (!value) {

            } else {
                const ds = this.getPromoPeriodsDataSource(sch);
                if (orig.isNewRecord()) {
                    ds.addItem(value, false);
                } else {
                    ds.replaceItem(value)
                }
            }

            sch.promoPeriods = this.getPromoPeriodsDataSource(sch).getData().map(value1 => {
                return BaseModel.unwrap((value1.getData()))
            })
        })

    }

    showProgramOverview(sch: RetailerPromoSchedule, program: RetailerPromoProgram, promoPeriod?: RetailerPromoPeriod) {
        PromoScheduleProgramOverviewComponent.open(this.router, sch, program, promoPeriod);
    }

    showProgramPlaylists(sch: RetailerPromoSchedule, program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod) {
        PromoPeriodPlaylistsOverviewComponent.open(this.router, sch, promoPeriod, program);
    }

    showEditSegmentDialogBox(program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod, segment?: DeviceSlotSegment) {
        if (!segment) {
            segment = new DeviceSlotSegment()
            segment.promoPeriod = promoPeriod
        }

        SegmentEditDialogComponent.open(this.svcLayout, program, segment).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(mergedSegment => {
            if (mergedSegment) {

                if (segment.isNewRecord()) {
                    this.svcLayout.showSnackMessage(`Segment ${mergedSegment.name} added`)
                } else {
                    this.svcLayout.showSnackMessage(`Segment ${mergedSegment.name} updated`)
                }

                promoPeriod = this.getSegmentsDataSource(promoPeriod).promoPeriod;

                const ds = this.getPromoPeriodsDataSource(promoPeriod.promoSchedule);

                promoPeriod.deviceSlotSegments = promoPeriod.deviceSlotSegments || [];
                promoPeriod.deviceSlotSegments.push(mergedSegment)
                ds.replaceItem(promoPeriod)
                this.getSegmentsDataSource(promoPeriod).setLocalData(promoPeriod.deviceSlotSegments)
                ds.setItemExpanded(promoPeriod, true)
            }
        })
    }

    private performDeviceSlotSegmentDelete(segment: DeviceSlotSegment) {
        this.svcLayout.confirm('Delete Segment', `Are you sure you want to delete ${segment.getDisplayName()}`).pipe(
            flatMap(value => {
                if (!value) {
                    return EMPTY;
                }
                const data: DeviceSlotSegmentInput = {
                    id: segment.id
                }
                return this.svcApi.mutateDeviceSlotSegment(MutationOperation.Delete, data)
            })
        ).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (!value.success) {
                this.svcLayout.showMutationResultMessage(value);
                return
            }
            this.svcLayout.showSnackMessage(`Segment ${segment.name} deleted`)

            const promoPeriod = segment.promoPeriod
            if (promoPeriod) {
                const ds = this.getPromoPeriodsDataSource(promoPeriod.promoSchedule);
                promoPeriod.deviceSlotSegments = promoPeriod.deviceSlotSegments || [];
                const idx = promoPeriod.deviceSlotSegments.findIndex(value1 => value1.id == segment.id)
                if (idx >= 0) {
                    promoPeriod.deviceSlotSegments.splice(idx, 1)
                    promoPeriod.deviceSlotSegments = [].concat(promoPeriod.deviceSlotSegments)
                    ds.replaceItem(promoPeriod)
                    this.getSegmentsDataSource(promoPeriod).setLocalData(promoPeriod.deviceSlotSegments)
                }

            }
        })
    }

    assignDeviceSlots(promoPeriod: RetailerPromoPeriod) {
        SegmentDeviceSlotAssignmentComponent.open(this.router, promoPeriod)
    }

    showCopySegmentsDialogBox(promoSchedule: RetailerPromoSchedule, program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod) {
        SegmentCopyDialogComponent.open(this.svcLayout, promoPeriod, program, promoSchedule).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(affectedPromoPeriod => {
            this.segmentsDataSource.set(promoPeriod.id, new SegmentDataSource(affectedPromoPeriod))
        })
    }

    canAssignDeviceSlots(promoPeriod: RetailerPromoPeriod): boolean {
        if (!promoPeriod || promoPeriod.isNewRecord()) {
            return false;
        }
        return promoPeriod.deviceSlotSegments?.length > 0
    }

    canAddSegments(promoPeriod: RetailerPromoPeriod) {
        if (!promoPeriod || promoPeriod.isNewRecord()) {
            return false;
        }
        return promoPeriod.isEditable || promoPeriod.isActive;
    }

    canManageSegments(promoPeriod: RetailerPromoPeriod) {
        if (!promoPeriod || promoPeriod.isNewRecord()) {
            return false;
        }
        return promoPeriod.isPending;
    }

    canCopySegments(promoPeriod: RetailerPromoPeriod) {
        return this.canManageSegments(promoPeriod)
    }

    showProgramSurveys(sch: RetailerPromoSchedule, program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod) {
        PromoPeriodSurveyOverviewComponent.open(this.router, sch, promoPeriod, program);
    }

    showSubmissions(program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod) {
        ProgramSubmissionsOverviewComponent.open(this.router, promoPeriod, program)
    }

    showHomeScreenPlaylist(program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod) {
        HomescreenPlaylistComponentComponent.open(this.router, promoPeriod, program)
    }
}

const TOMORROW = new Date(Date.now() + Utils.DAY_MILLIS);

class PromoPeriodRowEditor extends GridRowEditor<RetailerPromoPeriod> {
    programSearchCriterias: SearchFieldCriteria[];

    public form: FormGroup;

    promoProgramSelectableValues: NamedValue[] = [];

    public minEndDate: Date = TOMORROW;
    public minStartDate: Date = TOMORROW;
    private isFirstPromoPeriod = false;
    private isLastPromoPeriod = false;
    promoPrograms: NamedValue[];

    constructor(
        public promoPeriod: RetailerPromoPeriod,
        private promoSchedule: RetailerPromoSchedule,
        private parent: PromoSchedulesForRetailerListComponent,
        programSearchCriteria: SearchFieldCriteria,
    ) {
        super();
        this.programSearchCriterias = [programSearchCriteria];

        this.form = parent.fb.group({
            name: [null, Validators.required],
            start_date: [null, Validators.required],
            end_date: [null, Validators.required],
        });

        this.promoPrograms = (promoPeriod.promoPrograms || []).map(value => {
            return NamedValue.from(value.id, value.name)
        })

        const startDateControl = this.form.controls.start_date;
        const endDateControl = this.form.controls.end_date;

        const promoPeriods = promoSchedule.promoPeriods || [];

        let isFirst = false;
        let isLast = false;

        if (promoPeriod.isNewRecord()) {
            isLast = true
            if (promoPeriods.length) {
                promoPeriod.startDate = new Date(promoPeriods[promoPeriods.length - 1].endDate.getTime() + Utils.DAY_MILLIS)
            } else {
                isFirst = true
            }
        } else {
            const idx = promoPeriods.indexOf(promoPeriod)
            isFirst = idx == 0
            isLast = idx == promoPeriods.length - 1

            startDateControl.disable()
            endDateControl.disable()

            if (isFirst && !promoPeriod.isActive) {
                startDateControl.enable()
            }
            if (isLast && !promoPeriod.isEnded) {
                endDateControl.enable()
            }
        }

        if (promoPeriod.startDate) {
            this.minEndDate = new Date(promoPeriod.startDate);
        } else {
            endDateControl.disable()
        }

        this.isFirstPromoPeriod = isFirst;
        this.isLastPromoPeriod = isLast;

        endDateControl.setValue(promoPeriod.endDate);
        this.adjustDefaultValueForEndDate(promoPeriod.startDate);
        startDateControl.setValue(promoPeriod.startDate);
        this.form.controls.name.setValue(promoPeriod.name);

        if (!isFirst) {
            startDateControl.disable()
        }
        if (!isLast) {
            endDateControl.disable()
        }

        this.promoProgramSelectableValues = (this.promoPeriod.promoPrograms || []).map(value => {
            return NamedValue.from(value.id, value.getDisplayName())
        })

    }

    onDateChanged(which: 'start_date' | 'end_date', ev: MatDatepickerInputEvent<Date>) {
        const d = ev.value as Date;
        if (which == 'start_date') {
            const endDateControl = this.form.controls.end_date;
            if (this.isLastPromoPeriod) {
                endDateControl.enable();
                this.adjustDefaultValueForEndDate(d);
            }
        }
    }

    private adjustDefaultValueForEndDate(startDate: Date) {
        const endDateControl = this.form.controls.end_date;
        if (!startDate) {
            return
        }
        this.minEndDate = new Date(startDate);
        if (endDateControl.value) {
            return
        }
        if (!this.isLastPromoPeriod) {
            return
        }
        if (this.promoSchedule.defaultPromoPeriodDurationDays <= 0) {
            return
        }
        endDateControl.setValue(new Date(startDate.getTime() + (this.promoSchedule.defaultPromoPeriodDurationDays - 1) * Utils.DAY_MILLIS))
    }


    get dayDuration(): string {
        return Utils.getDayDurationString(this.form.controls.start_date.value, this.form.controls.end_date.value, true);
    }


    isValid(): boolean {
        if (!this.form.valid) {
            return false
        }

        if (this.promoPrograms.length == 0) {
            return false
        }

        return true;
    }

    onProgramsSelected(values: NamedValue[]) {
        this.promoPrograms = values || [];
    }

    save() {
        this.promoPeriod.startDate = this.form.controls.start_date.value;
        this.promoPeriod.endDate = this.form.controls.end_date.value;
        this.promoPeriod.name = this.form.controls.name.value.toString().trim();

        // this.isBusy = true;
        const op = this.promoPeriod.isNewRecord() ? MutationOperation.Create : MutationOperation.Update;

        console.warn('END DATE', this.form.controls.end_date.value)

        const data: RetailerPromoPeriodMutationInput = {
            id: this.promoPeriod.getStringId(),
            promoScheduleId: this.promoSchedule.getStringId(),
            startDate: this.getUtcDate('start_date').toISOString(),
            endDate: this.getUtcDate('end_date').toISOString(),
            name: this.form.controls.name.value.toString().trim(),
            promoProgramIds: this.promoPrograms.map(value => value.value)
        }

        this.parent.svcApi.mutatePromoPeriod(op, data, PROMO_PERIOD_GQL_FIELDS).pipe(
            takeUntil(Utils.onDestroy(this.parent))
        ).subscribe(value => {
            if (value.success) {
                this.dismiss(value.data)
            } else {
                this.parent.svcLayout.showMutationResultMessage(value)
            }
        })

    }

    private makeUtcDate(d: Date) {
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    }

    private getUtcDate(formField: 'start_date' | 'end_date'): Date {
        return this.makeUtcDate(this.form.controls[formField].value as Date)
    }
}

interface RetailerPromoScheduleFeedFilter {
    id?: string
    ids?: string[]
    query?: string
    cursor?: string
    retailerIds?: string[]
}

class PromoSchedulesLoader extends BaseCursorLoader<RetailerPromoSchedule> {

    private filters: Partial<RetailerPromoScheduleFeedFilter> = {};
    private retailer: Retailer;


    constructor(private component: PromoSchedulesForRetailerListComponent, obs: Observable<RetailerPromoScheduleFeedFilter>) {
        super();

        obs.subscribe(value => {
            this.invalidate()
        })
    }

    setRetailer(ret: Retailer) {
        this.retailer = ret;
        this.invalidate();
    }

    next(startFrom: string): Observable<CursorFeed<RetailerPromoSchedule>> {
        if (!this.retailer) {
            return EMPTY;
        }
        const filters: any = {};
        Object.assign(filters, this.filters);
        filters.retailerIds = [this.retailer?.id];
        if (startFrom) {
            Object.assign(filters, {cursor: startFrom});
        }
        return this.component.svcApi.rawQueryNoCache({
            query: SCHEDULE_LIST_QUERY,
            variables: {
                filter: filters
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data'];
                const c = CursorFeed.create(rawData, RetailerPromoSchedule, 'retailerPromoSchedules')
                return c;
            })
        )
    }
}

class SegmentDataSource extends ModelListDataSource<DeviceSlotSegment> {
    constructor(public promoPeriod: RetailerPromoPeriod) {
        super({
            columns: [
                {
                    key: 'name',
                    label: 'Segment',
                    valueReader: (item: DeviceSlotSegment) => {
                        return item.name
                    }
                },
                {
                    key: 'program',
                    label: 'Program',
                    valueReader: (item: DeviceSlotSegment) => {
                        return item.deviceSlotType?.retailerPromoProgram?.name
                    }
                },
                {
                    key: 'deviceSlotType',
                    label: 'Device Slot Type',
                    valueReader: (item: DeviceSlotSegment) => {
                        return item.deviceSlotType?.name
                    }
                },
                {
                    key: 'defaultBrandSlotsCount',
                    label: 'Default brand slots count',
                    valueReader: (item: DeviceSlotSegment) => {
                        return item.defaultBrandSlotsCount
                    }
                },
                {
                    key: 'nrOfDeviceSlotsAssigned',
                    label: 'Assigned device slots',
                    valueReader: (item: DeviceSlotSegment) => {
                        return item.deviceSlots ? item.deviceSlots.length : 0
                    }
                }
            ]
        });

        // dirty - set the parent period to the child segments 
        for (const item of promoPeriod.deviceSlotSegments) {
            item.promoPeriod = promoPeriod
        }

        const data = promoPeriod.deviceSlotSegments || [];
        this.setLocalData(data)
    }
}

const PROMO_PERIOD_GQL_FIELDS = `
id
name
status
startDate
endDate
activateAt
promoSchedule{
    id
}
promoPrograms {
    id
    name
    active
    deviceSlotTypes {
        id
        name
    }
    kioskDeviceApp {
        id
        app_name
    }
}
deviceSlotSegments {
    id
    name
    deviceSlotType {
        id
        name
        retailerPromoProgram {
            id
            name
            deviceSlotTypes {
                id
                name
            }   
        }
    }    
    deviceSlots {
        id
    }
    defaultBrandSlotsCount
}
`

const SCHEDULE_GQL_FIELDS = `
id
name
defaultPromoPeriodDurationDays
retailer{
    id
    retailer_name
}
promoPeriods{
    ${PROMO_PERIOD_GQL_FIELDS}
}
`
const SCHEDULE_LIST_QUERY = gql`
    query schedules($filter: RetailerPromoScheduleFeedFilter!){
        data: retailerPromoSchedules(filter:$filter){
            cursor
            retailerPromoSchedules{
                ${SCHEDULE_GQL_FIELDS}
            }
        }
    }
`;

class PromoPeriodsDataSource extends ModelListDataSource<RetailerPromoPeriod> {

    schedulePromoPrograms: RetailerPromoProgram[] = [];
    private schedulePromoProgramsSignature = '';

    constructor(public promoSchedule: RetailerPromoSchedule, private parent: PromoSchedulesForRetailerListComponent) {
        super({
            columns: [
                {
                    key: 'promo_period_status',
                    label: '',
                    width: '25px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return item.isActive
                    }
                }, {
                    key: 'name',
                    label: 'Name',
                    width: '150px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return item.name
                    }
                }, {
                    key: 'start_date',
                    label: 'Start Date',
                    width: '150px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return Utils.formatDate(item.startDate, 'M/d/yyyy');// Utils.formatShortDate(item.startDate)
                    }
                }, {
                    key: 'end_date',
                    label: 'End Date',
                    width: '150px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return Utils.formatDate(item.endDate, 'M/d/yyyy');
                    }
                }, {
                    key: 'duration',
                    label: 'Duration',
                    width: '100px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return Utils.getDayDurationString(item.startDate, item.endDate, true);
                    }
                }, {
                    key: 'release_time',
                    label: 'Release Time',
                    width: '150px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return Utils.formatDate(item.activateAt, 'M/d/yyyy HH:mm');
                    }
                }, {
                    key: 'segments',
                    label: 'Segments',
                    width: '150px',
                    valueReader: (item: RetailerPromoPeriod) => {
                        return item.deviceSlotSegments ? item.deviceSlotSegments.length : 0;
                    }
                }, {
                    key: 'programs',
                    label: 'Programs',
                }
            ]
        });


        this.setLocalData(promoSchedule.promoPeriods || []);
        this.refreshScheduledPrograms();
        this.dataSubject.pipe(
            takeUntil(Utils.onDestroy(parent)),
        ).subscribe(value => {
            this.refreshScheduledPrograms();
        })
    }

    private refreshScheduledPrograms() {
        const programsMap = new Map<string, RetailerPromoProgram>();
        const items = (this.loadedData as any as RetailerPromoPeriod[]);
        items.forEach(promoPeriod => {
            (promoPeriod.promoPrograms || []).forEach(prog => {
                const item = prog.unwrap();
                programsMap.set(item.id, item)
            })
        });

        const schedulePromoPrograms = Array.from(programsMap.values());
        const newSignature = schedulePromoPrograms.map(value => value.id).sort().join('-');
        if (newSignature != this.schedulePromoProgramsSignature) {
            this.schedulePromoPrograms = schedulePromoPrograms;
            this.schedulePromoProgramsSignature = newSignature;
            this.parent.changeDetectorRef.detectChanges();
        }
        // this.parent.changeDetectorRef.detectChanges();
    }

}

