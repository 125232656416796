import {Injectable} from '@angular/core';
import {AsyncSubject, Observable, Subject, Subscription, throwError, timer} from 'rxjs';
import {catchError, map, startWith, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {Utils} from '@looma/shared/utils';
import {DeviceCommand} from '@looma/shared/types/device_commands';
import {RemoteJobService, RunningJobInfo} from '@looma/shared/services/remote-job.service';
import {UploadedFileInfo, UploadSession, UploadState} from '@looma/shared/services/upload.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

export type GetJobTokenFunc = (file: UploadedFileInfo) => Observable<string>;

@Injectable({
    providedIn: 'root'
})
export class ToastNotificationService {

    private _notifications: ToastNotification[] = [];
    private _notificationPipe = new Subject<ToastNotification[]>();
    private notificationSubs: Map<ToastNotification, Subscription> = new Map();
    private _deviceCommandsCompleted = new Subject<{ deviceId: string, remoteCommand: DeviceCommand }>();

    constructor(public svcRemoteJob: RemoteJobService, public sanitizer: DomSanitizer) {
    }

    create(cfg: NotificationConfig): ToastNotification {
        if (!cfg.id) {
            cfg.id = `notif-${Date.now()}`
        }

        const existing = this.getNotification(cfg.id);
        if (existing) {
            existing.update(cfg);
            return existing
        }

        const notif = new ToastNotification(this, cfg);

        setTimeout(() => {
            this.add(notif);
        }, 0);

        return notif;
    }

    update(cfg: NotificationConfig): ToastNotification | null {
        const existing = this.getNotification(cfg.id);
        if (existing) {
            existing.update(cfg);
            return existing
        }
        return null;
    }
    
    public getNotification(id: string): ToastNotification {
        return this._notifications.find(value => value.id === id);
    }


    add(notif: ToastNotification): boolean {
        const idx = this._notifications.indexOf(notif);
        if (idx < 0) {
            this._notifications.push(notif);
            const sub = notif.onUpdated().pipe(
            ).subscribe(value => {
                this._notificationPipe.next(this._notifications);
                if (value.isDismissed) {
                    this.notificationSubs.get(notif).unsubscribe();
                    this.notificationSubs.delete(notif);
                }
            });
            this.notificationSubs.set(notif, sub);
            return true
        }
        return false
    }

    remove(notif: ToastNotification): void {
        const idx = this._notifications.indexOf(notif);
        if (idx > 0) {
            this._notifications.splice(idx, 0)
        }
    }

    getNotifications(): Observable<ToastNotification[]> {
        return this._notificationPipe.pipe(
            startWith(this._notifications),
            map(value => {
                return (value as ToastNotification[]).filter(notif => !notif.isDismissed)
            })
        )
    }

    // emits values once the upload and the associated job is done
    connect(session: UploadSession, 
            getJobToken?: (file: UploadedFileInfo) => Observable<string> | null,
            toastNotif?: ToastNotification
    
    ): Observable<{
        file: UploadedFileInfo,
        job?: RunningJobInfo,
        notif: ToastNotification
    }> {
        const pipe = new Subject<{ file: UploadedFileInfo, job?: RunningJobInfo, notif: ToastNotification }>();

        session.onFileAdded().subscribe((file: UploadedFileInfo) => {
            const notif = toastNotif || this.create({
                title: file.fileName,
                style: ToastNotificationStyle.Loading,
                progress: -1,
                dismissable: false,
            });

            let progressSub: Subscription = null;
            file.onStateChanged.subscribe(state => {
                switch (state) {
                    case UploadState.Uploading:
                    case UploadState.FetchBlob:
                        if (!progressSub) {
                            progressSub = file.onProgress.subscribe(progress => {
                                let desc = 'Preparing';
                                let perc = -1;
                                if (progress) {
                                    perc = progress.progress;
                                    if (perc >= 100) {
                                        perc = -1
                                    }
                                    if (progress.type === 'download') {
                                        desc = 'Getting file data'
                                    } else if (progress.type === 'upload') {
                                        desc = 'Uploading file'
                                    }
                                }
                                notif.update({
                                    progress: perc,
                                    description: desc,
                                })
                            })
                        }
                        break;
                    case UploadState.Canceled:
                        Utils.unsubscribe(progressSub);
                        notif.update({
                            style: ToastNotificationStyle.Error,
                            description: 'Upload Canceled',
                            dismissable: true,
                        });
                        break;
                    case UploadState.Failure:
                        Utils.unsubscribe(progressSub);
                        const msg = file.errorMessage || 'Upload Failure';
                        notif.update({
                            style: ToastNotificationStyle.Error,
                            description: msg,
                            dismissable: true,
                        });
                        break;
                    case UploadState.UploadSuccess:
                        Utils.unsubscribe(progressSub);
                        if (!Utils.isFunction(getJobToken)) {
                            pipe.next({file: file, notif: notif});
                            notif.update({
                                style: ToastNotificationStyle.Success,
                                progress: -1,
                                description: 'File uploaded',
                                dismissable: true,
                            });
                            break
                        }

                        notif.update({
                            style: ToastNotificationStyle.Loading,
                            progress: -1,
                            description: 'Preparing',
                        });

                        getJobToken(file).subscribe(firebaseJobId => {
                            notif.watchJob(firebaseJobId).subscribe(value => {
                                if (value.isDone) {
                                    pipe.next({file: file, job: value, notif: notif});
                                }
                            })
                        }, error1 => {
                            notif.update({
                                style: ToastNotificationStyle.Error,
                                progress: -1,
                                description: Utils.extractErrorMessage(error1, 'Unexpected error'),
                                dismissable: true,
                            });
                        });
                        break;
                }
            })
        });

        return pipe;

    }

    showSuccess(action: string, duration: number = 5000): ToastNotification {
        const notif = this.create({
            title: 'Success',
            description: `${action} successfully`,
            style: ToastNotificationStyle.Success,
            dismissable: true,
        });
        notif.startDismissTimer(duration);
        return notif;
    }

    showError(failedToSavePlaylist: string): ToastNotification {
        const notif = this.create({
            title: 'Error',
            description: failedToSavePlaylist,
            style: ToastNotificationStyle.Error,
            dismissable: true
        });
        notif.startDismissTimer(5000);
        return notif;
    }
}


export interface NotificationConfig {
    id?: string
    title?: string
    description?: string | SafeHtml
    style?: ToastNotificationStyle
    dismissable?: boolean
    progress?: number;
    actions?: { id: string, text: string, handler: () => void }[];
}


export class ToastNotification {
    id: string;
    style: ToastNotificationStyle = ToastNotificationStyle.None;
    title: string;
    description: string;
    dismissable: boolean;
    isDismissed: boolean;
    progress?: number;
    actions: { id: string, text: string, handler: () => void }[];
    params: { key: any, value: any }[];

    notificationPipe = new Subject<ToastNotification>();
    private dismissSubject = new AsyncSubject<ToastNotification>()


    private _dismissSub: Subscription;

    constructor(private owner: ToastNotificationService, cfg?: NotificationConfig) {
        if (cfg) {
            cfg.style = cfg.style || ToastNotificationStyle.Info;
            if (!cfg.hasOwnProperty('dismissable')) {
                cfg.dismissable = true
            }
            this.update(cfg)
        }
    }

    onDismissed(): Observable<ToastNotification> {
        return this.dismissSubject
    }

    onUpdated(): Observable<ToastNotification> {
        return this.notificationPipe;
    }

    notifyUpdated(): void {
        this.notificationPipe.next(this)
    }

    dismiss(): void {
        Utils.unsubscribe(this._dismissSub);
        if (!this.isDismissed) {
            this.isDismissed = true;
            this.apply(notif => notif.isDismissed = true);
            this.notificationPipe.complete();
            this.dismissSubject.next(this)
        }
    }

    apply(fn: (notif: ToastNotification) => void): void {
        fn(this);
        this.notifyUpdated()
    }

    startDismissTimer(duration?: number): void {
        if (this.isDismissed) {
            return
        }
        if (!Utils.isUnsubscribed(this._dismissSub)) {
            return
        }

        this._dismissSub = timer(duration || 20000).pipe(
            takeUntil(this.dismissSubject)
        ).subscribe(_ => {
            this.dismiss();
        })
    }

    update(cfg: NotificationConfig): void {
        this.apply(notif => {
            if (typeof cfg.description === 'string') {
                cfg.description = this.owner.sanitizer.bypassSecurityTrustHtml(cfg.description)
            }

            Object.assign(notif, cfg);
        });
    }

    watchJob(firebaseJobId: string, onSuccess?: (job: RunningJobInfo, toast: ToastNotification) => void): Observable<RunningJobInfo> {
        return this.owner.svcRemoteJob.watchJob(firebaseJobId).pipe(
            takeWhile(job => !job.isDone, true),
            tap(job => {
                if (!job.isDone) {
                    const hint = job.getCurrentProgressHint();
                    if (hint) {
                        this.update({
                            description: hint.label,
                            progress: hint.value,
                        })
                    }
                } else if (job.isSuccess) {
                    if (onSuccess) {
                        onSuccess(job, this);
                    } else {
                        this.update({
                            description: job.message,
                            style: ToastNotificationStyle.Success,
                            dismissable: true
                        })
                    }

                } else if (job.isFailure) {
                    this.update({
                        description: job.message,
                        style: ToastNotificationStyle.Error,
                        dismissable: true
                    })
                }
            }),
            catchError(err => {
                this.update({
                    description: Utils.extractErrorMessage(err, 'Processing failure'),
                    style: ToastNotificationStyle.Error,
                    dismissable: true
                });
                return throwError(err)
            })
        )
    }


}

export enum ToastNotificationStyle {
    None = 'none',
    Loading = 'loading',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
    Success = 'success',
    Completed = 'completed',
}
